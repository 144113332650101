import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './css/App.css';
import './css/logged-in.css';
import './css/comming-soon.css';
import iconClose from './assets/images/close.png';
import commingSoon from './assets/images/comming-soon.png';

function ErrorMobile() {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        const returnLocation = sessionStorage.getItem('return_location');
        if (returnLocation === 'create') {
            navigate('/create');
        } else {
            navigate('/');
        }

    };

    const errorMessage = localStorage.getItem("LoadingError");

    return (
        <div className='comming-wrapper'>

            <div className='div-top-bottom' style={{ width: "100%" }}>
                <div style={{ width: "100%", display: "flex", justifyContent: 'end' }}>
                    <Link to="/" style={{ "width": "auto" }}>
                        <div className='top-icons'><img src={iconClose} alt="close icon" /></div>
                    </Link>
                </div>
            </div>

            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly", weight: "100%", height: "100%" }}>
                <img src={commingSoon} style={{ marginTop: "15px" }} alt="wrenches" />

                <div className='comming-text'><p>Error...</p></div>
                <div className='comming-text-small'>
                    <p style={{ width: "100%", textAlign: "center" }}>{errorMessage}</p>
                </div>
            </div>

            <div className='first-footer' style={{ width: "100%", marginBottom: "0px" }}>
                <button
                    className='App-button'
                    onClick={handleButtonClick}

                    style={{ width: "100%" }}>
                    <div className='button-row'>
                        <p style={{ lineHeight: "1" }}>Try again</p>
                    </div>
                </button>

            </div>

        </div>

    );

}

export default ErrorMobile;