import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import './css/App.css'; // Assuming you want to use the same CSS as your App component
import './css/success.css';
import './css/login.css';

import { fetchUserDataLogin, userLoginFunction } from './utils';
import { userSignupFunction } from './utils';
import { useResponsiveWrapperVisibility } from './utils';
import femaleSignUpDesktop from './assets/images/female-sign-up-desktop.png';
import iconGoogle from './assets/images/icon-google.png';
// import iconMail from './assets/images/icon-email.png';
import iconApple from './assets/images/icon-apple.png';
import iconBack from './assets/images/icon-back.png';
import iconEye from './assets/images/eye-slash.png';
import iconClose from './assets/images/sign-up-close.png';

function Login() {

    const desktopWidth = 768;
    const [isMobile, setIsMobile] = useState(window.innerWidth < desktopWidth);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < desktopWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const googleOAuthUrl = process.env.REACT_APP_GOOGLE_OAUTH_URL;
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID; // Your Google client ID
    const redirectUri = `${process.env.REACT_APP_CLIENT_URL}/oauth-redirect`; // Your redirect URI
    const responseType = 'code'; // or 'code' if you're using PKCE or server-side flow
    const scope = encodeURIComponent('openid profile email');
    const state = generateRandomString(); // You need to implement this function

    // Construct the Google OAuth URL
    const googleAuthUrl = `${googleOAuthUrl}?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=${responseType}&scope=${scope}&state=${state}`;

    // Function to handle the click event
    const handleGoogleLogin = () => {
        // Store the state in localStorage or sessionStorage for later validation
        window.localStorage.setItem('oauth_state', state);
        // Open the Google OAuth URL in a new tab
        window.location.href = googleAuthUrl;
    };

    const handleAppleLogin = () => {
        // Redirect to your backend endpoint that starts the "Sign in with Apple" process
        window.location.href = `${process.env.REACT_APP_SERVER_URL}/auth/apple`;
    };

    const [isFirstDivVisible, setIsFirstDivVisible] = useState(true);

    const toggleDivs = () => {
        setIsFirstDivVisible(!isFirstDivVisible);
        setLoginDivInvisible();
        clearInputs();
    };

    const [isLoginDivVisible, setIsLoginDivVisible] = useState(false);

    const toggleLoginDivs = () => {
        setIsLoginDivVisible(!isLoginDivVisible);
        clearInputs();
    };

    const setLoginDivInvisible = () => {
        setIsLoginDivVisible(false);
    };

    // State variables for the input fields
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [emailSignUp, setEmailSignUp] = useState('');
    const [passwordSignUp, setPasswordSignUp] = useState('');
    const [verificationSignUp, setVerificationSignUp] = useState('');

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Check if there is a state in the location object and if it has a message
        if (location.state && location.state.message) {
            setErrorMessage(location.state.message);
            // Clear the state after it's been read to prevent the message from reappearing
            navigate(location.pathname, { replace: true, state: {} });
        }
    }, [location, navigate]);

    // Update state variables on input change
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleEmailSignUpChange = (event) => {
        setEmailSignUp(event.target.value);
    };

    const handlePasswordSignUpChange = (event) => {
        setPasswordSignUp(event.target.value);
    };

    const handleVerificationSignUpChange = (event) => {
        setVerificationSignUp(event.target.value);
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex pattern
        return re.test(email);
    };

    // Modify userLoginFunction to accept email and password
    const handleLoginClick = () => {

        if (!validateEmail(email)) {
            setErrorMessage('Please enter a valid email address.');

            return;
        }
        if (password.length < 6) {
            setErrorMessage('Password must be at least 6 characters long.');
            return;
        }
        setErrorMessage(''); // Clear any previous error messages
        clearInputs();
        userLoginFunction(email, password, navigate);
    };

    const handleSignUpClick = () => {
        if (!validateEmail(emailSignUp)) {
            setErrorMessage('Please enter a valid email address.');

            return;
        }
        if (passwordSignUp.length < 6) {
            setErrorMessage('Password must be at least 6 characters long.');
            return;
        }

        setErrorMessage(''); // Clear any previous error messages
        clearInputs();
        userSignupFunction(emailSignUp, passwordSignUp, verificationSignUp, navigate);
    };

    const clearInputs = () => {
        setEmail('');
        setPassword('');
        setErrorMessage('');
        setEmailSignUp('');
        setPasswordSignUp('');
        setVerificationSignUp('');
    };

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const [userId, setUserId] = useState(null);

    useEffect(() => {
        fetchUserDataLogin(() => { }, () => { }, () => { }, setUserId);
    }, []);

    useEffect(() => {
        if (userId !== null && userId !== undefined) {
            navigate('/'); // Replace '/logged-in' with your actual route
        }
    }, [userId, navigate]);

    const isWrapperVisible = useResponsiveWrapperVisibility();

    return (
        <div className="App" style={{ marginTop: isWrapperVisible ? '-120px' : '', justifyContent: isMobile ? 'flex-start' : 'center' , height: isMobile ? '100%' : '90vh' }}>

            {isMobile ? (
                <></>
            ) : (
                <>
                    {isFirstDivVisible ? (
                        <div className='top-line-account-login' style={{ justifyContent: 'flex-end', marginTop: isWrapperVisible ? '140px' : '20px' }}>
                            <div onClick={() => navigate('/')} className='top-icons'>
                                <img src={iconClose} alt="close icon" style={{ width: '20px', height: '20px', marginTop: '10px' }} />
                            </div>
                        </div >
                    ) : (
                        <div className='top-line-account-login' style={{ justifyContent: 'space-between', marginTop: isWrapperVisible ? '140px' : '20px' }}>
                            <div onClick={toggleDivs} className='top-icons'>
                                <img src={iconBack} alt="back icon" style={{ width: '20px', height: '20px', marginTop: '10px' }} />
                            </div>
                            <div onClick={() => navigate('/')} className='top-icons'>
                                <img src={iconClose} alt="close icon" style={{ width: '20px', height: '20px', marginTop: '10px' }} />
                            </div>
                        </div >
                    )
                    }
                </>
            )
            }
            <div className='login-image-text-wrapper'>
                {isMobile ? (
                    <>
                        <div
                            style={{
                                width: "100%",
                                paddingBottom: "100%", // This creates a 1:1 aspect ratio
                                position: "relative",
                                overflow: "hidden",
                                marginTop: isWrapperVisible ? '120px' : '0px'
                            }}
                        >
                            <img
                                src={femaleSignUpDesktop}
                                alt="female"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    objectPosition: "top",
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                }}
                            />
                        </div>

                        <div className="overlay-content-login">
                            {isFirstDivVisible ? (
                                <div className='top-line-account-login' style={{ justifyContent: 'flex-end', marginTop: isWrapperVisible ? '150px' : '' }}>
                                    <div onClick={() => navigate('/')} className='top-icons six-top-line-element' style={{marginTop: '10px' }} >
                                        <img src={iconClose} alt="close icon" style={{ width: '20px', height: '20px'}} />
                                    </div>
                                </div >
                            ) : (
                                <div className='top-line-account-login' style={{ justifyContent: 'space-between', marginTop: isWrapperVisible ? '150px' : '' }}>
                                    <div onClick={toggleDivs} className='top-icons six-top-line-element' style={{marginTop: '10px' }}>
                                        <img src={iconBack} alt="back icon" style={{ width: '20px', height: '20px' }} />
                                    </div>
                                    <div onClick={() => navigate('/')} className='top-icons six-top-line-element' style={{marginTop: '10px' }}>
                                        <img src={iconClose} alt="close icon" style={{ width: '20px', height: '20px' }} />
                                    </div>
                                </div >
                            )
                            }
                        </div>
                    </>
                ) : (
                    <>
                        <div className='gradient-image-wrapper' style={{ width: "70%", height: "auto" }}>
                            <div className='login-image' style={{ width: "100%"}}>
                                <img src={femaleSignUpDesktop} alt="female" style={{ width: "80%" }} />
                            </div>
                            <div className="background-gradient-login" ></div>

                        </div>
                    </>
                )
                }

                <div className='first-text-wrapper login-first-text-wrapper' style={{ marginTop: isMobile ? '20px' : '30px' }}>

                    <div style={{ marginBottom: "15px" }}>
                        <p style={{ fontSize: "24px" }}>Log in</p>
                    </div>

                    {isFirstDivVisible ? (
                        <div>
                            {isLoginDivVisible ? (
                                <div>
                                    <p className='make-sure-text' style={{ textAlign: "center", marginBottom: "5px" }}>
                                        Don't have an account?
                                    </p>
                                    <span onClick={toggleLoginDivs} className='success-email-gradient' style={{ fontWeight: '500', textDecoration: "none" }}>Sign up</span>

                                    <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "10px" }}>
                                        <input type="text" value={email} onChange={handleEmailChange} placeholder="Email" className="sign-up-input" style={{ height: '52px' }} />

                                        <div style={{ position: 'relative' }}>
                                            <input
                                                type={showPassword ? 'text' : 'password'}
                                                value={password}
                                                onChange={handlePasswordChange}
                                                placeholder="Password"
                                                className="sign-up-input"
                                                style={{ height: '52px' }}
                                            />
                                            <img
                                                src={iconEye}
                                                alt="Toggle password visibility"
                                                onClick={togglePasswordVisibility}
                                                style={{
                                                    position: 'absolute',
                                                    cursor: 'pointer',
                                                    right: '20px', // Adjust the position as needed
                                                    top: '50%',
                                                    transform: 'translateY(-50%)'
                                                }}
                                            />
                                        </div>


                                        <button onClick={handleLoginClick} className="App-button" style={{ "width": "100%", height: '52px', paddingBottom: '5px' }}>Log in</button>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {/* <p className='make-sure-text' style={{ textAlign: "center", marginBottom: "5px" }}>
                                        Already have an account?
                                    </p>
                                    <span onClick={toggleLoginDivs} className='success-email-gradient' style={{ fontWeight: '500', textDecoration: "none" }}>Log in</span> */}

<div style={{height:'20px'}}></div>

                                    <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "10px" }}>
                                        <button className="login-button" onClick={handleGoogleLogin}><img src={iconGoogle} alt="google icon" className='no-border-image' /><span>Conitnue with Google</span></button>
                                        <button className="login-button" onClick={handleAppleLogin}><img src={iconApple} alt="google icon" className='no-border-image' /><span>Conitnue with Apple</span></button>
                                        {/* <button onClick={toggleDivs} className="login-button"><img src={iconMail} alt="google icon" className='no-border-image' /><span>Conitnue with Email</span></button> */}
                                    </div>
                                </div>
                            )}
                            <div className="error-message-container">
                                {errorMessage && <span className="error-message">{errorMessage}</span>}
                            </div>
                        </div>
                    ) : (
                        <div>
                            {isLoginDivVisible ? (
                                <div>
                                    <p className='make-sure-text' style={{ textAlign: "center", marginBottom: "5px" }}>
                                        Don't have an account?
                                    </p>
                                    <span onClick={toggleLoginDivs} className='success-email-gradient' style={{ fontWeight: '500', textDecoration: "none" }}>Sign up</span>

                                    <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "10px" }}>
                                        <input type="text" value={email} onChange={handleEmailChange} placeholder="Email" className="sign-up-input" style={{ height: '52px' }} />

                                        <div style={{ position: 'relative' }}>
                                            <input
                                                type={showPassword ? 'text' : 'password'}
                                                value={password}
                                                onChange={handlePasswordChange}
                                                placeholder="Password"
                                                className="sign-up-input"
                                                style={{ height: '52px' }}
                                            />
                                            <img
                                                src={iconEye}
                                                alt="Toggle password visibility"
                                                onClick={togglePasswordVisibility}
                                                style={{
                                                    position: 'absolute',
                                                    cursor: 'pointer',
                                                    right: '20px', // Adjust the position as needed
                                                    top: '50%',
                                                    transform: 'translateY(-50%)'
                                                }}
                                            />
                                        </div>

                                        <button onClick={handleLoginClick} className="App-button" style={{ "width": "100%", height: '52px', paddingBottom: '5px' }}>Log in</button>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <p className='make-sure-text' style={{ textAlign: "center", marginBottom: "5px" }}>
                                        Already have an account?
                                    </p>
                                    <span onClick={toggleLoginDivs} className='success-email-gradient' style={{ fontWeight: '500', textDecoration: "none" }}>Log in</span>

                                    <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "10px" }}>
                                        <input type="text" value={emailSignUp} onChange={handleEmailSignUpChange} placeholder="Email" className="sign-up-input" style={{ height: '52px' }} />
                                        <input type="text" value={verificationSignUp} onChange={handleVerificationSignUpChange} placeholder="Verification Code (optional)" className="sign-up-input" style={{ height: '52px' }} />
                                        {/* <input type="text" value={passwordSignUp} onChange={handlePasswordSignUpChange} placeholder="Set password 6 characters minimum" className="sign-up-input" /> */}

                                        <div style={{ position: 'relative' }}>
                                            <input
                                                type={showPassword ? 'text' : 'password'}
                                                value={passwordSignUp}
                                                onChange={handlePasswordSignUpChange}
                                                placeholder="Set password 6 characters minimum"
                                                className="sign-up-input"
                                                style={{ height: '52px' }}
                                            />
                                            <img
                                                src={iconEye}
                                                alt="Toggle password visibility"
                                                onClick={togglePasswordVisibility}
                                                style={{
                                                    position: 'absolute',
                                                    cursor: 'pointer',
                                                    right: '20px', // Adjust the position as needed
                                                    top: '50%',
                                                    transform: 'translateY(-50%)'
                                                }}
                                            />
                                        </div>

                                        <button onClick={handleSignUpClick} className="App-button" style={{ "width": "100%", height: '52px', paddingBottom: '5px' }}>Sign up</button>
                                    </div>
                                </div>
                            )}
                            <div className="error-message-container">
                                {errorMessage && <span className="error-message">{errorMessage}</span>}
                            </div>
                        </div>

                    )}


                </div>
            </div>
        </div>
    );
}

// Utility function to generate a random string for the state parameter
function generateRandomString(length = 32) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

export default Login;