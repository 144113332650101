import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import iconUnlock from './assets/images/unlock.png';
import iconResultEye from './assets/images/icon-result-eye.png';
import infoResultCircle from './assets/images/info-result-circle.png';
import iconResultFire from './assets/images/icon-result-fire.png';
import iconResultFace from './assets/images/icon-result-face.png';
import iconResultNose from './assets/images/icon-result-nose.png';
import iconResultLips from './assets/images/icon-result-lips.png';
import { useResponsiveWrapperVisibility } from './utils';

import './css/history-item-view.css';
import './css/history.css';

const HistoryItemViewAnalyze = ({ item, onClose, userStatus }) => {

    const desktopWidth = 768;
    const [isMobile, setIsMobile] = useState(window.innerWidth < desktopWidth);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < desktopWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const navigate = useNavigate();

    useEffect(() => {
        // Disable right-click
        const handleContextMenu = (e) => {
            e.preventDefault();
        };
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    // Parse the message if it's a string
    let message = item.messages[0]?.message;
    if (typeof message === 'string') {
        try {
            message = JSON.parse(message);
        } catch (error) {
            console.error('Error parsing message:', error);
        }
    }

    const face = message?.face ?? {};
    const nose = message?.nose ?? {};
    const lips = message?.lips ?? {};
    const eyes = message?.eyes ?? {};
    const sexuality = message?.sexuality ?? {};
    const summary = message?.summary ?? {};

    const isWrapperVisible = useResponsiveWrapperVisibility();

    return (
        <div className="history-item-view" style={{ userSelect: 'none', marginTop: isWrapperVisible ? '110px' : '', paddingBottom: isWrapperVisible ? '110px' : '', paddingLeft: isMobile ? '10px' : '', paddingRight: isMobile ? '10px' : '' }}>
            <div className="history-item-view-content scrollable-div">
                <button className="close-button" style={{ top: isWrapperVisible ? '110px' : '10px' }} onClick={onClose}>Close</button>
                <img src={item.image} alt="History" className="history-item-view-image" style={{ width: isMobile ? "100%" : "500px" }} />
                <div className="history-item-view-messages">
                    <div style={{ zIndex: "5", marginTop: "20px" }}>
                        <div className='result-item'>
                            <div className='result-item-top'>
                                <img src={iconResultFace} alt="face icon" />
                                <span style={{ color: "white" }}>Face shape</span>
                                <span className={`${userStatus !== 'active' ? 'result-blur' : ''}`} style={{ color: "white", flexGrow: "1", textAlign: "end" }}>{face.value || "Oval"}</span>
                            </div>
                            <div className='result-item-bottom' style={{ width: isMobile ? "90vw" : "800px" }}>
                                <span className={`result-span-item-bottom ${userStatus !== 'active' ? 'result-blur' : ''}`} style={{ color: 'white' }}>
                                    {face.advice || `Confidence is attractive. Approach her with a positive attitude and a smile. Remember, it's okay to be nervous, but don't let it control the interaction.`}
                                </span>

                                <div className={`result-red-block ${userStatus !== 'active' ? 'result-blur' : ''}`}>
                                    <span className='result-span-red'>{face.avoid || "Try to avoid speaking about money, greedines"}</span>
                                    <img src={infoResultCircle} alt="info icon" />
                                </div>
                                {isMobile ? (
                                    <>
                                        {userStatus !== 'active' && (
                                            <button className="login-button info-box-button" onClick={() => navigate('/paywall')}>
                                                <div className='button-row'>
                                                    <img src={iconUnlock} alt="upload icon" />
                                                    <p style={{ lineHeight: "1" }}>Reveal</p>
                                                </div>
                                            </button>
                                        )}
                                    </>
                                ) : (
                                    <>

                                    </>
                                )}
                            </div>

                        </div>

                        <div className='result-item'>
                            <div className='result-item-top'>
                                <img src={iconResultNose} alt="nose icon" />
                                <span style={{ color: "white" }}>Nose</span>
                                <span className={`${userStatus !== 'active' ? 'result-blur' : ''}`} style={{ color: "white", flexGrow: "1", textAlign: "end" }}>{nose.value || "Greek"}</span>
                            </div>
                            <div className='result-item-bottom' style={{ width: isMobile ? "90vw" : "800px" }}>
                                <span className={`result-span-item-bottom ${userStatus !== 'active' ? 'result-blur' : ''}`} style={{ color: 'white' }}>
                                    {nose.advice || `Begin with a light, casual topic. Comment on something relevant to the situation you're both in, like the event you're at, the environment, or a shared experience.`}
                                </span>

                                <div className={`result-red-block ${userStatus !== 'active' ? 'result-blur' : ''}`}>
                                    <span className='result-span-red'>{nose.avoid || "Try to avoid speaking big noses"}</span>
                                    <img src={infoResultCircle} alt="info icon" />
                                </div>
                                {isMobile ? (
                                    <>
                                        {userStatus !== 'active' && (
                                            <button className="login-button info-box-button" onClick={() => navigate('/paywall')}>
                                                <div className='button-row'>
                                                    <img src={iconUnlock} alt="upload icon" />
                                                    <p style={{ lineHeight: "1" }}>Reveal</p>
                                                </div>
                                            </button>
                                        )}
                                    </>
                                ) : (
                                    <>

                                    </>
                                )}
                            </div>
                        </div>

                        <div className='result-item'>
                            <div className='result-item-top'>
                                <img src={iconResultLips} alt="lips icon" />
                                <span style={{ color: "white" }}>Lips</span>
                                <span className={`${userStatus !== 'active' ? 'result-blur' : ''}`} style={{ color: "white", flexGrow: "1", textAlign: "end" }}>{lips.value || "Thin"}</span>
                            </div>
                            <div className='result-item-bottom' style={{ width: isMobile ? "90vw" : "800px" }}>
                                <span className={`result-span-item-bottom ${userStatus !== 'active' ? 'result-blur' : ''}`} style={{ color: 'white' }}>
                                    {lips.advice || `Confidence is attractive. Approach her with a positive attitude and a smile. Remember, it's okay to be nervous, but don't let it control the interaction.`}
                                </span>

                                <div className={`result-red-block ${userStatus !== 'active' ? 'result-blur' : ''}`}>
                                    <span className='result-span-red'>{lips.avoid || "Try to avoid speaking about money, greedines"}</span>
                                    <img src={infoResultCircle} alt="info icon" />
                                </div>
                                {userStatus !== 'active' && (
                                    <button className="login-button info-box-button" onClick={() => navigate('/paywall')}>
                                        <div className='button-row'>
                                            <img src={iconUnlock} alt="upload icon" />
                                            <p style={{ lineHeight: "1" }}>Reveal</p>
                                        </div>
                                    </button>
                                )}
                            </div>
                        </div>

                        <div className='result-item'>
                            <div className='result-item-top'>
                                <img src={iconResultEye} alt="eye icon" />
                                <span style={{ color: "white" }}>Eyes</span>
                                <span className={`${userStatus !== 'active' ? 'result-blur' : ''}`} style={{ color: "white", flexGrow: "1", textAlign: "end" }}>{eyes.value || "Almond"}</span>
                            </div>
                            <div className='result-item-bottom' style={{ width: isMobile ? "90vw" : "800px" }}>
                                <span className={`result-span-item-bottom ${userStatus !== 'active' ? 'result-blur' : ''}`} style={{ color: 'white' }}>
                                    {eyes.advice || `Confidence is attractive. Approach her with a positive attitude and a smile. Remember, it's okay to be nervous, but don't let it control the interaction.`}
                                </span>

                                <div className={`result-red-block ${userStatus !== 'active' ? 'result-blur' : ''}`}>
                                    <span className='result-span-red'>{eyes.avoid || "Try to avoid speaking about money, greedines"}</span>
                                    <img src={infoResultCircle} alt="info icon" />
                                </div>
                                {isMobile ? (
                                    <>
                                        {userStatus !== 'active' && (
                                            <button className="login-button info-box-button" onClick={() => navigate('/paywall')}>
                                                <div className='button-row'>
                                                    <img src={iconUnlock} alt="upload icon" />
                                                    <p style={{ lineHeight: "1" }}>Reveal</p>
                                                </div>
                                            </button>
                                        )}
                                    </>
                                ) : (
                                    <>

                                    </>
                                )}
                            </div>
                        </div>

                        <div className='result-item' >
                            <div className='result-item-top' style={{ background: "linear-gradient(301deg, rgba(238, 46, 46, 1) 0%, rgba(251, 105, 105, 1) 80%, rgba(255, 123, 123, 1) 100%)" }}>
                                <img src={iconResultFire} alt="fire icon" />
                                <span style={{ color: "white" }}>Sexuality</span>
                                <span className={`${userStatus !== 'active' ? 'result-blur' : ''}`} style={{ color: "white", flexGrow: "1", textAlign: "end" }}>{sexuality.value || "Medium"}</span>
                            </div>
                            <div className='result-item-bottom' style={{ width: isMobile ? "90vw" : "800px", border: "none", background: "linear-gradient(301deg, rgba(238, 46, 46, 1) 0%, rgba(251, 105, 105, 1) 80%, rgba(255, 123, 123, 1) 100%)" }}>
                                <span className={`result-span-item-bottom ${userStatus !== 'active' ? 'result-blur' : ''}`} style={{ color: 'white' }}>
                                    {sexuality.advice || `Pay attention to what she says and respond appropriately. Show that you're listening by nodding, maintaining eye contact, and asking follow-up questions based on her responses.`}
                                </span>

                                <div className={`result-red-block ${userStatus !== 'active' ? 'result-blur' : ''}`} style={{ background: "#292A2B" }}>
                                    <span className='result-span-red' style={{ color: 'white' }}>{sexuality.avoid || "Be polite. Avoid vulgar jokes"}</span>
                                    <img src={infoResultCircle} alt="info icon" />
                                </div>
                                {isMobile ? (
                                    <>
                                        {userStatus !== 'active' && (
                                            <button className="login-button info-box-button" onClick={() => navigate('/paywall')}>
                                                <div className='button-row'>
                                                    <img src={iconUnlock} alt="upload icon" />
                                                    <p style={{ lineHeight: "1" }}>Reveal</p>
                                                </div>
                                            </button>
                                        )}
                                    </>
                                ) : (
                                    <>

                                    </>
                                )}
                            </div>
                            {isMobile ? (
                                <>
                                    <div className="background-gradient-result-item" ></div>
                                </>
                            ) : (
                                <>

                                </>
                            )}

                        </div>
                    </div>
                    <p className='result-conclusion' style={{ color: "white" }}>Conclusion</p>

                    <div className='result-item' style={{ marginTop: "0px", marginBottom: "20px" }}>

                        <div className='result-item-bottom' style={{ width: isMobile ? "90vw" : "800px" }}>
                            <span className={`result-span-item-bottom ${userStatus !== 'active' ? 'result-blur' : ''}`} style={{color:'white'}}>
                                {summary.advice || `Pay attention to what she says
                        and respond appropriately.
                        Show that you're listening by nodding,
                        maintaining eye contact, and asking follow-up
                        questions based on her responses.`}
                            </span>

                            <div className={`result-red-block ${userStatus !== 'active' ? 'result-blur' : ''}`}>
                                <span className='result-span-red'>{summary.avoid || "Be polite. Avoid vulgar jokes"}</span>
                                <img src={infoResultCircle} alt="info icon" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div style={{ height: isWrapperVisible ? '110px' : '' }}></div>
        </div>
    );
};

export default HistoryItemViewAnalyze;