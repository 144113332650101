import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";

import './css/App.css';
import './css/paywall.css';
import imagePayWallFemale from './assets/images/paywall-female.png';
import iconClose from './assets/images/close.png';
import iconV from './assets/images/iconv.png';
import iconLock from './assets/images/lock.png';
import { fetchUserData } from './utils';
import { useResponsiveWrapperVisibility } from './utils';

function Paywall() {
    const desktopWidth = 768;
    const [isMobile, setIsMobile] = useState(window.innerWidth < desktopWidth);
    const [selectedOption, setSelectedOption] = useState(1);
    const public_stripe_key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    const navigate = useNavigate();

    const handleOptionClick = (option) => {
        console.log(option);
        setSelectedOption(option);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < desktopWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [userEmail, setUserEmail] = useState(null);

    useEffect(() => {
        fetchUserData(() => { }, setUserEmail, () => { }, () => { });
    }, []);

    const handleSubscription = async () => {

        // window.location.href = '/success';

        const stripePromise = await loadStripe(public_stripe_key);
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/create-stripe-session`, {
            method: "POST",
            headers: { "Content-Type": "Application/JSON" },
            body: JSON.stringify({ selectedOption, userEmail })
        });

        if (response.status === 409) {
            const data = await response.json();
            if (data && data.redirectUrl) {
                window.location.href = data.redirectUrl; // redirect to billing portal if user is already subscribed
            }
        } else {
            const session = await response.json();
            stripePromise.redirectToCheckout({
                sessionId: session.id,
            });
        }
    };

    const isWrapperVisible = useResponsiveWrapperVisibility();

    return ( 
        <div className={`App six-mobile ${isWrapperVisible ? 'App-banner-visible' : ''}`}>
            {isMobile ? (
                <>
                    <div className='six-image-wrapper'>
                        <img className="fade-img" src={imagePayWallFemale} alt="female" />
                        <div className="overlay-content">
                            <div className='six-top-line' style={{ marginTop: isWrapperVisible ? '120px' : '' }}>
                                <div className='six-top-line-element' onClick={() => navigate('/')} >
                                    <img src={iconClose} alt="close icon" style={{ width: "16px", height: "16px" }} />
                                </div>
                                {/* <div className='six-top-line-element' style={{ "paddingBottom": "9px", "paddingTop": "7px", "paddingLeft": "20px", "paddingRight": "20px" }}>
                                    <span style={{ fontWeight: "600" }}>Restore</span>
                                </div> */}
                            </div>
                            <p className='unlock-p-mobile' style={{ marginTop: isWrapperVisible ? '10px' : '' }}>Unlock AI Dating</p>
                            <div className='get-access'>
                                <p>Get access to</p>
                                <div>
                                    <img src={iconV} alt="v icon" />
                                    <span>Unlimited reports</span>
                                </div>
                                <div>
                                    <img src={iconV} alt="v icon" />
                                    <span>Most advanced AI analysis</span>
                                </div>
                                <div>
                                    <img src={iconV} alt="v icon" />
                                    <span>100+ tips per report</span>
                                </div>
                                <div>
                                    <img src={iconV} alt="v icon" />
                                    <span>Face traits rarity</span>
                                </div>
                                <div>
                                    <img src={iconV} alt="v icon" />
                                    <span>Unlock all features</span>
                                </div>
                                <div>
                                    <img src={iconV} alt="v icon" />
                                    <span>No Commitments, Cancel anytime</span>
                                </div>
                            </div>
                            <div className='option-box-wrapper'>
                                <div className={`option-box ${selectedOption === 0 ? 'payment-with-border' : 'payment-no-border'}`}
                                    onClick={() => handleOptionClick(0)}>
                                    <div className='vertical-stack'>
                                        <span className='grey-option-text'>Pay Every Week</span>
                                        <span className='white-price-text'>$5.99 per week</span>
                                    </div>
                                </div>

                                <div className={`option-box ${selectedOption === 1 ? 'payment-with-border' : 'payment-no-border'}`}
                                    onClick={() => handleOptionClick(1)}>
                                    <div className="option-texts vertical-stack">
                                        <span className="grey-option-text">Pay Annually</span>
                                        <span className="white-price-text">$29.99 per year</span>
                                    </div>
                                    <div className={`top-right-div ${selectedOption === 1 ? 'top-right-div-blue' : 'top-right-div-gray'}`}>
                                        <span>-90%</span>
                                    </div>
                                </div>
                            </div>
                            <div className='secured'>
                                <img src={iconLock} alt="lock icon" />
                                <span>Secured with Stripe. Cancel anytime</span>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className='top-icon-wrapper' style={{width:"800px", marginTop: isWrapperVisible ? '120px' : '' }}>
                        <div className='top-icons' onClick={() => navigate('/')}><img src={iconClose} alt="icon user" style={{ width: "24px", height: "24px" }} /></div>
                    </div>
                    <div className='desktop-wrapper' style={{ paddingTop: isWrapperVisible ? '0px' : '50px' }}>
                        <div className='six-images-wrapper-desktop'>
                            <img src={imagePayWallFemale} alt="female" />
                        </div>
                        <div className='ubest-desktop'>
                            <p className='unlock-p-desktop' style={{ margin: isWrapperVisible ? '10px' : '30px' }}>Unlock AI Dating</p>
                            <p style={{ "fontSize": "16px", "fontWeight": "500", "opacity": "65%" }}>Get access to</p>
                            <div className='v-text-desktop'>
                                <div>
                                    <img src={iconV} alt="v icon" />
                                    <span>Unlimited reports</span>
                                </div>
                                <div>
                                    <img src={iconV} alt="v icon" />
                                    <span>Most advanced AI analysis</span>
                                </div>
                            </div>
                            <div className='v-text-desktop'>
                                <div>
                                    <img src={iconV} alt="v icon" />
                                    <span>100+ tips per report</span>
                                </div>
                                <div>
                                    <img src={iconV} alt="v icon" />
                                    <span>Face traits rarity</span>
                                </div>
                                <div>
                                    <img src={iconV} alt="v icon" />
                                    <span>Unlock all features</span>
                                </div>
                                <div>
                                    <img src={iconV} alt="v icon" />
                                    <span>No Commitments, Cancel anytime</span>
                                </div>
                            </div>
                            <div className='v-text-desktop'>
                                <div>
                                    <img src={iconV} alt="v icon" />
                                    <span>No Commitments, Cancel anytime</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='option-box-wrapper'>
                        <div className={`option-box ${selectedOption === 0 ? 'payment-with-border' : 'payment-no-border'}`}
                            onClick={() => handleOptionClick(0)}>
                            <div className='vertical-stack'>
                                <span className='grey-option-text'>Pay Every Week</span>
                                <span className='white-price-text'>$5.99 per week</span>
                            </div>
                        </div>

                        <div className={`option-box ${selectedOption === 1 ? 'payment-with-border' : 'payment-no-border'}`}
                            onClick={() => handleOptionClick(1)}>
                            <div className="option-texts vertical-stack">
                                <span className="grey-option-text">Pay Annually</span>
                                <span className="white-price-text">$29.99 per year</span>
                            </div>
                            <div className={`top-right-div ${selectedOption === 1 ? 'top-right-div-blue' : 'top-right-div-gray'}`}>
                                <span>-90%</span>
                            </div>
                        </div>
                    </div>


                </>
            )}
            <div className='sixth-footer'>
                <button className="App-button" onClick={() => handleSubscription()}><p style={{ lineHeight: "1" }}>Continue</p></button>
                <div>
                    <a href="/terms" className='sixth-anchors' target="_blank" rel="noopener noreferrer">Terms of use</a>
                    <a href="/privacy" className='sixth-anchors' target="_blank" rel="noopener noreferrer">Privacy policy</a>
                </div>
            </div>
        </div>


    );
}

export default Paywall;