import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './css/App.css';
import './css/result-create.css';
import './css/result.css';
import './css/loading.css';

import gotItFemaleCreate11 from './assets/images/got-it-create-11.png';
import iconResultBack from './assets/images/icon-result-back.png';
import iconUnlock from './assets/images/unlock.png';
import ResultCreateItem from './result-create-item.js';
import { fetchUserDataLogin } from './utils';

class ResponseItem {
    constructor(id, image, type, gender, message, history, date) {
        this.id = id;
        this.image = image;
        this.type = type;
        this.gender = gender;
        this.message = message;
        this.history = history;
        this.date = date;
    }
}

function ResultCreate() {
    const desktopWidth = 768;
    const [isMobile, setIsMobile] = useState(window.innerWidth < desktopWidth);
    const [responseItems, setResponseItems] = useState([]);
    const [itemsToShow, setItemsToShow] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const navigate = useNavigate();
    const [userStatus, setUserStatus] = useState('');

    useEffect(() => {
        fetchUserDataLogin(() => { }, () => { }, setUserStatus, () => { });
    }, []);

    useEffect(() => {

        fetchResponses();

    }, [userStatus]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < desktopWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const uploadedFileUrlCreate = localStorage.getItem('uploadedFileUrlCreate');
    const imageUrl = uploadedFileUrlCreate || gotItFemaleCreate11;

    const fetchResponses = () => {

        const uploadedFileUrlCreate = localStorage.getItem('uploadedFileUrlCreate');
        if (uploadedFileUrlCreate) {
            fetch(`${process.env.REACT_APP_SERVER_URL}/get-responses`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ image: uploadedFileUrlCreate }),
            })
                .then(response => response.json())
                .then(data => {
                    const items = data.map(item => new ResponseItem(
                        item.id,
                        item.image,
                        item.type,
                        item.gender,
                        item.message,
                        item.history,
                        item.date
                    ));
                    setResponseItems(items);
                    // console.log("items: ", items);
                    setItemsToShow(items.filter(item => item.history === 1));
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    };

    const handleGetMoreClick = () => {
        if (userStatus === 'active') {
            const invisibleItems = responseItems.filter(item => item.history === 0);
            console.log("invisibleItems.length: ", invisibleItems.length);
            if (invisibleItems.length > 0) {
                setIsFetching(true);
                console.log("do 3 visible");
                const updatedItems = [...responseItems];
                const updatedItemsToShow = [...itemsToShow];
                const idsToUpdate = [];
                let count = 0;

                for (let i = 0; i < updatedItems.length && count < 3; i++) {
                    if (updatedItems[i].history === 0) {
                        updatedItems[i].history = 1; // Set history to 1
                        updatedItemsToShow.push(updatedItems[i]);
                        idsToUpdate.push(updatedItems[i].id);
                        count++;
                    }
                }

                setResponseItems(updatedItems);
                setItemsToShow(updatedItemsToShow);

                fetch(`${process.env.REACT_APP_SERVER_URL}/visibility`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ids: idsToUpdate }),
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log('Visibility updated:', data);
                        setIsFetching(false);
                        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
                    })
                    .catch(error => {
                        console.error('Error updating visibility:', error);
                        setIsFetching(false);
                    });
            } else {

                console.log("do fetch new 15");
                setIsFetching(true);
                fetch(`${process.env.REACT_APP_SERVER_URL}/img`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ image: uploadedFileUrlCreate }),
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log('New items added:', data);
                        // Trigger the useEffect to update responseItems
                        fetchResponses();
                        setIsFetching(false);

                        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
                    })
                    .catch(error => {
                        console.error('Error adding new items:', error);
                        setIsFetching(false);
                    });

            }

        } else {
            navigate('/paywall');
        }
    };

    return (
        <div className='result-wrapper result-create-items-wrapper'>
            <div className='div-top-bottom'>
                <div style={{ width: "100%", display: "flex", justifyContent: 'start', marginTop: "20px" }}>
                    <Link to={userStatus === 'active' ? "/history" : "/paywall"} style={{ "width": "auto" }}>
                        <div className='top-icons'><img src={iconResultBack} alt="icon back" style={{width:'28px', height:'auto'}}/></div>
                    </Link>
                </div>
            </div>

            <div className='gradient-image-wrapper' style={{ width: "70%", height: "auto" }}>
                <div className='result-image' style={{ marginTop: "30px", marginBottom: "50px" }}>
                    <img src={imageUrl} alt="chat" style={{ width: "auto", height: "242px" }} />
                </div>
                <div className="background-gradient-result-create" ></div>
            </div>

            <div className='first-text-wrapper-create' style={{ marginBottom: "30px" }}>
                {isMobile ? (
                    <div style={{ textAlign: "center" }}>
                        <p style={{ fontSize: "24px" }}>
                            Press the button<br />to create messages
                        </p>
                    </div>
                ) : (
                    <div style={{ marginBottom: "24px" }}>
                        <p style={{ fontSize: "32px" }}>
                            Press the button to create messages
                        </p>
                    </div>
                )}
            </div>
            <div className="result-items-create-mobile">
                {itemsToShow.map((item, index) => (
                    <div key={item.id} className="result-create-item-wrapper">
                        <ResultCreateItem
                            text={item.message}
                            isRightCorner={index % 2 === 0}
                            visible={userStatus === 'active'}
                        />
                        {index === 0 && userStatus !== "active" && (
                            <button className="login-button info-box-button " onClick={() => navigate('/paywall')}>
                                <div className='button-row'>
                                    <img src={iconUnlock} alt="unlock icon" />
                                    <p style={{ lineHeight: "1", color: 'black' }}>Reveal</p>
                                </div>
                            </button>
                        )}
                    </div>
                ))}
            </div>
            <div style={{ height: isMobile ? "75px" : "175px" }}></div>
            <div className='first-footer result-stick' style={{ marginBottom: '0px', zIndex: "15", width: '100%' }}>
                <button className="App-button" onClick={handleGetMoreClick} disabled={isFetching}>
                    <div className='button-row'>
                        <p style={{ lineHeight: "1" }} >
                            {isFetching ? "Loading..." : "Get more!"}
                        </p>
                    </div>
                </button>
            </div>
        </div>
    );
}

export default ResultCreate;