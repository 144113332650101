import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import './css/App.css';
import './css/result.css';
import './css/logged-in.css';
import './css/success.css';
import './css/login.css';

import iconResultBack from './assets/images/icon-result-back.png';
import iconResultFace from './assets/images/icon-result-face.png';
import iconResultNose from './assets/images/icon-result-nose.png';
import iconResultLips from './assets/images/icon-result-lips.png';
import iconResultEye from './assets/images/icon-result-eye.png';
import iconResultFire from './assets/images/icon-result-fire.png';
import infoResultCircle from './assets/images/info-result-circle.png';
import resultFemale from './assets/images/result-female.png';
import iconUnlock from './assets/images/unlock.png';
import { fetchUserDataLogin } from './utils';

class ResponseItem {
    constructor(id, image, type, gender, message, history, date) {
        this.id = id;
        this.image = image;
        this.type = type;
        this.gender = gender;
        this.message = message;
        this.history = history;
        this.date = date;
    }
}

function Result() {
    const desktopWidth = 768;
    const [isMobile, setIsMobile] = useState(window.innerWidth < desktopWidth);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < desktopWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // Disable right-click
        const handleContextMenu = (e) => {
            e.preventDefault();
        };
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    const [responseItems, setResponseItems] = useState([]);
    const navigate = useNavigate();
    const [userStatus, setUserStatus] = useState('');

    useEffect(() => {
        fetchUserDataLogin(() => { }, () => { }, setUserStatus, () => { });
    }, []);

    useEffect(() => {
        fetchResponses();

    }, [userStatus]);

    const uploadedFileUrlAnalyze = localStorage.getItem('uploadedFileUrlAnalyze');
    const imageUrl = uploadedFileUrlAnalyze || resultFemale;

    const fetchResponses = () => {
        const uploadedFileUrlAnalyze = localStorage.getItem('uploadedFileUrlAnalyze');
        if (uploadedFileUrlAnalyze) {
            fetch(`${process.env.REACT_APP_SERVER_URL}/get-responses-analyze`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ image: uploadedFileUrlAnalyze }),
            })
                .then(response => response.json())
                .then(data => {
                    const items = data.map(item => new ResponseItem(
                        item.id,
                        item.image,
                        item.type,
                        item.gender,
                        item.message,
                        item.history,
                        item.date
                    ));
                    setResponseItems(items);
                    console.log("Response items analyze: ", items);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    };

     // Parse the message if it's a string
     let message = responseItems[0]?.message;
     if (typeof message === 'string') {
         try {
             message = JSON.parse(message);
         } catch (error) {
             console.error('Error parsing message:', error);
         }
     }
 
     const face = message?.face ?? {};
     const nose = message?.nose ?? {};
     const lips = message?.lips ?? {};
     const eyes = message?.eyes ?? {};
     const sexuality = message?.sexuality ?? {};
     const summary = message?.summary ?? {};

    return (
        <div className='result-wrapper'>
            <div className='div-top-bottom'>
                <div style={{ width: "100%", display: "flex", justifyContent: 'flex-start', marginTop: "20px" }}>
                    <Link to={userStatus === 'active' ? "/history" : "/paywall"} style={{ "width": "auto" }} >
                        <div className='top-icons'><img src={iconResultBack} alt="icon back" style={{width:'28px', height:'auto'}}/></div>
                    </Link>
                </div>
            </div>


            <div className='gradient-image-wrapper' style={{ width: "70%", height: "auto" }}>
                <div className='result-image'>
                    <img src={imageUrl} alt="female" style={{ width: "auto", height: "242px" }} />
                </div>
                <div className="background-gradient-result" ></div>
            </div>

            <div style={{ zIndex: "5", marginTop: "40px" }}>
                <div className='result-item'>
                    <div className='result-item-top'>
                        <img src={iconResultFace} alt="face icon" />
                        <span>Face shape</span>
                        <span className={`${userStatus !== 'active' ? 'result-blur' : ''}`} style={{ flexGrow: "1", textAlign: "end" }}>{face.value || "Oval"}</span>
                    </div>
                    <div className='result-item-bottom'>
                        <span className={`result-span-item-bottom ${userStatus !== 'active' ? 'result-blur' : ''}`}>
                            {face.advice || `Confidence is attractive. Approach her with a positive attitude and a smile. Remember, it's okay to be nervous, but don't let it control the interaction.`}
                        </span>

                        <div className={`result-red-block ${userStatus !== 'active' ? 'result-blur' : ''}`}>
                            <span className='result-span-red'>{face.avoid || "Try to avoid speaking about money, greedines"}</span>
                            <img src={infoResultCircle} alt="info icon" />
                        </div>
                        {isMobile ? (
                            <>
                                {userStatus !== 'active' && (
                                    <button className="login-button info-box-button" onClick={() => navigate('/paywall')}>
                                        <div className='button-row'>
                                            <img src={iconUnlock} alt="upload icon" />
                                            <p style={{ lineHeight: "1" }}>Reveal</p>
                                        </div>
                                    </button>
                                )}
                            </>
                        ) : (
                            <>

                            </>
                        )}
                    </div>

                </div>

                <div className='result-item'>
                    <div className='result-item-top'>
                        <img src={iconResultNose} alt="nose icon" />
                        <span>Nose</span>
                        <span className={`${userStatus !== 'active' ? 'result-blur' : ''}`} style={{ flexGrow: "1", textAlign: "end" }}>{nose.value || "Greek"}</span>
                    </div>
                    <div className='result-item-bottom'>
                        <span className={`result-span-item-bottom ${userStatus !== 'active' ? 'result-blur' : ''}`}>{nose.advice || `Begin with a light, casual topic. Comment on something relevant to the situation you're both in, like the event you're at, the environment, or a shared experience.`}</span>

                        <div className={`result-red-block ${userStatus !== 'active' ? 'result-blur' : ''}`}>
                            <span className='result-span-red'>{nose.avoid || "Try to avoid speaking big noses"}</span>
                            <img src={infoResultCircle} alt="info icon" />
                        </div>
                        {isMobile ? (
                            <>
                                {userStatus !== 'active' && (
                                    <button className="login-button info-box-button" onClick={() => navigate('/paywall')}>
                                        <div className='button-row'>
                                            <img src={iconUnlock} alt="upload icon" />
                                            <p style={{ lineHeight: "1" }}>Reveal</p>
                                        </div>
                                    </button>
                                )}
                            </>
                        ) : (
                            <>

                            </>
                        )}
                    </div>
                </div>

                <div className='result-item'>
                    <div className='result-item-top'>
                        <img src={iconResultLips} alt="lips icon" />
                        <span>Lips</span>
                        <span className={`${userStatus !== 'active' ? 'result-blur' : ''}`} style={{ flexGrow: "1", textAlign: "end" }}>{lips.value || "Thin"}</span>
                    </div>
                    <div className='result-item-bottom'>
                        <span className={`result-span-item-bottom ${userStatus !== 'active' ? 'result-blur' : ''}`}>{lips.advice || `Confidence is attractive. Approach her with a positive attitude and a smile. Remember, it's okay to be nervous, but don't let it control the interaction.`}</span>

                        <div className={`result-red-block ${userStatus !== 'active' ? 'result-blur' : ''}`}>
                            <span className='result-span-red'>{lips.avoid || "Try to avoid speaking about money, greedines"}</span>
                            <img src={infoResultCircle} alt="info icon" />
                        </div>
                        {userStatus !== 'active' && (
                            <button className="login-button info-box-button" onClick={() => navigate('/paywall')}>
                                <div className='button-row'>
                                    <img src={iconUnlock} alt="upload icon" />
                                    <p style={{ lineHeight: "1" }}>Reveal</p>
                                </div>
                            </button>
                        )}
                    </div>
                </div>

                <div className='result-item'>
                    <div className='result-item-top'>
                        <img src={iconResultEye} alt="eye icon" />
                        <span>Eyes</span>
                        <span className={`${userStatus !== 'active' ? 'result-blur' : ''}`} style={{ flexGrow: "1", textAlign: "end" }}>{eyes.value || "Almond"}</span>
                    </div>
                    <div className='result-item-bottom'>
                        <span className={`result-span-item-bottom ${userStatus !== 'active' ? 'result-blur' : ''}`}>{eyes.advice || `Confidence is attractive. Approach her with a positive attitude and a smile. Remember, it's okay to be nervous, but don't let it control the interaction.`}</span>

                        <div className={`result-red-block ${userStatus !== 'active' ? 'result-blur' : ''}`}>
                            <span className='result-span-red'>{eyes.avoid || "Try to avoid speaking about money, greedines"}</span>
                            <img src={infoResultCircle} alt="info icon" />
                        </div>
                        {isMobile ? (
                            <>
                                {userStatus !== 'active' && (
                                    <button className="login-button info-box-button" onClick={() => navigate('/paywall')}>
                                        <div className='button-row'>
                                            <img src={iconUnlock} alt="upload icon" />
                                            <p style={{ lineHeight: "1" }}>Reveal</p>
                                        </div>
                                    </button>
                                )}
                            </>
                        ) : (
                            <>

                            </>
                        )}
                    </div>
                </div>

                <div className='result-item' >
                    <div className='result-item-top' style={{ background: "linear-gradient(301deg, rgba(238, 46, 46, 1) 0%, rgba(251, 105, 105, 1) 80%, rgba(255, 123, 123, 1) 100%)" }}>
                        <img src={iconResultFire} alt="fire icon" />
                        <span>Sexuality</span>
                        <span className={`${userStatus !== 'active' ? 'result-blur' : ''}`} style={{ flexGrow: "1", textAlign: "end" }}>{sexuality.value || "Medium"}</span>
                    </div>
                    <div className='result-item-bottom' style={{ border: "none", background: "linear-gradient(301deg, rgba(238, 46, 46, 1) 0%, rgba(251, 105, 105, 1) 80%, rgba(255, 123, 123, 1) 100%)" }}>
                        <span className={`result-span-item-bottom ${userStatus !== 'active' ? 'result-blur' : ''}`}>{sexuality.advice || `Pay attention to what she says and respond appropriately. Show that you're listening by nodding, maintaining eye contact, and asking follow-up questions based on her responses.`}</span>

                        <div className={`result-red-block ${userStatus !== 'active' ? 'result-blur' : ''}`} style={{ background: "#292A2B" }}>
                            <span className='result-span-red'>{sexuality.avoid || "Be polite. Avoid vulgar jokes"}</span>
                            <img src={infoResultCircle} alt="info icon" />
                        </div>
                        {isMobile ? (
                            <>
                                {userStatus !== 'active' && (
                                    <button className="login-button info-box-button" onClick={() => navigate('/paywall')}>
                                        <div className='button-row'>
                                            <img src={iconUnlock} alt="upload icon" />
                                            <p style={{ lineHeight: "1" }}>Reveal</p>
                                        </div>
                                    </button>
                                )}
                            </>
                        ) : (
                            <>

                            </>
                        )}
                    </div>
                    {isMobile ? (
                        <>
                            <div className="background-gradient-result-item" ></div>
                        </>
                    ) : (
                        <>

                        </>
                    )}

                </div>
            </div>
            <p className='result-conclusion' >Conclusion</p>

            <div className='result-item' style={{ marginTop: "0px", marginBottom: "20px" }}>

                <div className='result-item-bottom'>
                    <span className={`result-span-item-bottom ${userStatus !== 'active' ? 'result-blur' : ''}`}>{summary.advice || `Pay attention to what she says
                        and respond appropriately.
                        Show that you're listening by nodding,
                        maintaining eye contact, and asking follow-up
                        questions based on her responses.`}</span>

                    <div className={`result-red-block ${userStatus !== 'active' ? 'result-blur' : ''}`}>
                        <span className='result-span-red'>{summary.avoid || "Be polite. Avoid vulgar jokes"}</span>
                        <img src={infoResultCircle} alt="info icon" />
                    </div>
                </div>
            </div>

        </div >
    );
}

export default Result;