import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import './css/App.css';
import './css/sign-up.css';
import './css/account.css';
import './css/history.css';

import { userLoginFunction } from './utils';
import { userSignupFunction } from './utils';
import iconGoogleDesktop from './assets/images/desktop-google-icon.png';
// import iconMailDesktop from './assets/images/desktop-mail-icon.png';
import iconAppleDesktop from './assets/images/desktop-apple-icon.png';
import femaleSignUpDesktop from './assets/images/female-sign-up-desktop.png';
import iconBack from './assets/images/sign-up-back.png';
import iconClose from './assets/images/sign-up-close.png';
import iconEye from './assets/images/eye-slash.png';

function SignUp({ onToggle }) {
    const [isFirstDivVisible, setIsFirstDivVisible] = useState(true);

    const toggleDivs = () => {
        setIsFirstDivVisible(!isFirstDivVisible);
        setLoginDivInvisible();
        clearInputs();
    };

    const [isLoginDivVisible, setIsLoginDivVisible] = useState(false);

    const toggleLoginDivs = () => {
        setIsLoginDivVisible(!isLoginDivVisible);
        clearInputs();
    };

    const setLoginDivInvisible = () => {
        setIsLoginDivVisible(false);
    };

    const googleOAuthUrl = process.env.REACT_APP_GOOGLE_OAUTH_URL;
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID; // Your Google client ID
    const redirectUri = `${process.env.REACT_APP_CLIENT_URL}/oauth-redirect`; // Your redirect URI
    const responseType = 'code'; // or 'code' if you're using PKCE or server-side flow
    const scope = encodeURIComponent('openid profile email');
    const state = generateRandomString(); // You need to implement this function

    // Construct the Google OAuth URL
    const googleAuthUrl = `${googleOAuthUrl}?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=${responseType}&scope=${scope}&state=${state}`;

    // Function to handle the click event
    const handleGoogleLogin = () => {
        // Store the state in localStorage or sessionStorage for later validation
        window.localStorage.setItem('oauth_state', state);
        // Open the Google OAuth URL in a new tab
        window.location.href = googleAuthUrl;
    };

    const handleAppleLogin = () => {
        // Redirect to your backend endpoint that starts the "Sign in with Apple" process
        window.location.href = `${process.env.REACT_APP_SERVER_URL}/auth/apple`;
    };

    // State variables for the input fields
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [emailSignUp, setEmailSignUp] = useState('');
    const [passwordSignUp, setPasswordSignUp] = useState('');
    const [verificationSignUp, setVerificationSignUp] = useState('');

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Check if there is a state in the location object and if it has a message
        if (location.state && location.state.message) {
            setErrorMessage(location.state.message);
            // Clear the state after it's been read to prevent the message from reappearing
            navigate(location.pathname, { replace: true, state: {} });
        }
    }, [location, navigate]);

    // Update state variables on input change
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleEmailSignUpChange = (event) => {
        setEmailSignUp(event.target.value);
    };

    const handlePasswordSignUpChange = (event) => {
        setPasswordSignUp(event.target.value);
    };

    const handleVerificationSignUpChange = (event) => {
        setVerificationSignUp(event.target.value);
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex pattern
        return re.test(email);
    };

    // Modify userLoginFunction to accept email and password
    const handleLoginClick = () => {
        if (!validateEmail(email)) {
            setErrorMessage('Please enter a valid email address.');

            return;
        }
        if (password.length < 6) {
            setErrorMessage('Password must be at least 6 characters long.');
            return;
        }
        setErrorMessage(''); // Clear any previous error messages
        clearInputs();
        onToggle();
        userLoginFunction(email, password, navigate);
    };

    const handleSignUpClick = () => {
        if (!validateEmail(emailSignUp)) {
            setErrorMessage('Please enter a valid email address.');

            return;
        }
        if (passwordSignUp.length < 6) {
            setErrorMessage('Password must be at least 6 characters long.');
            return;
        }

        setErrorMessage(''); // Clear any previous error messages
        clearInputs();
        onToggle();
        userSignupFunction(emailSignUp, passwordSignUp, verificationSignUp, navigate);
    };

    const clearInputs = () => {
        setEmail('');
        setPassword('');
        setErrorMessage('');
        setEmailSignUp('');
        setPasswordSignUp('');
        setVerificationSignUp('');
    };

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className='sign-up-wrapper'>

            <img src={femaleSignUpDesktop} alt="female" className='no-border-image sign-up-female-photo' />

            {isFirstDivVisible ? (

                <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start", padding: "20px" }}>
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end" }}>
                        <img onClick={onToggle} src={iconClose} alt="close icon" className='no-border-image' />
                    </div>

                    {isLoginDivVisible ? (
                        <div style={{ width: "550px", display: "flex", flexDirection: "column", gap: "10px", alignItems: "start" }}>
                            <p className='history-archive-text' style={{ marginTop: "10px" }}>Log in</p>
                            <p className='history-archive-text' style={{ textAlign: "center", fontSize: "20px", fontWeight: "500", marginBottom: "40px" }}>
                                Don't have an account? <span onClick={toggleLoginDivs} className='span-first-gradient' style={{ fontWeight: '500', textDecoration: "none" }}>Sign up</span>
                            </p>
                        </div>
                    ) : (
                        <div style={{ width: "550px", display: "flex", flexDirection: "column", gap: "10px", alignItems: "start" }}>
                            {/* <p className='history-archive-text' style={{ marginTop: "10px" }}>Sign up</p> */}
                            <p className='history-archive-text' style={{ marginTop: "10px" }}>Log in</p>
                            {/* <p className='history-archive-text' style={{ textAlign: "center", fontSize: "20px", fontWeight: "500", marginBottom: "40px" }}>
                                Already have an account? <span onClick={toggleLoginDivs} className='span-first-gradient' style={{ fontWeight: '500', textDecoration: "none" }}>Log in</span>
                            </p> */}
                            <div style={{height: "87px"}}></div>
                        </div>
                    )}

                    {isLoginDivVisible ? (
                        <div style={{ width: "550px", display: "flex", flexDirection: "column", gap: "10px" }}>
                            <input type="text" value={email} onChange={handleEmailChange} placeholder="Email" className="sign-up-input" />

                            <div style={{ position: 'relative' }}>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={handlePasswordChange}
                                    placeholder="Password"
                                    className="sign-up-input"
                                />
                                <img
                                    src={iconEye}
                                    alt="Toggle password visibility"
                                    onClick={togglePasswordVisibility}
                                    style={{
                                        position: 'absolute',
                                        cursor: 'pointer',
                                        right: '20px', // Adjust the position as needed
                                        top: '50%',
                                        transform: 'translateY(-50%)'
                                    }}
                                />
                            </div>

                            <button onClick={handleLoginClick} className="App-button" style={{ "width": "100%" }}>Log in</button>
                            {errorMessage && <span className="error-message">{errorMessage}</span>}
                        </div>

                    ) : (
                        <div style={{ width: "550px", display: "flex", flexDirection: "column", gap: "10px" }}>

                            <button className="login-button-desktop" onClick={handleGoogleLogin}>
                                <img src={iconGoogleDesktop} alt="google icon" className='no-border-image' /><span>Conitnue with Google</span>
                            </button>

                            <button className="login-button-desktop" onClick={handleAppleLogin}><img src={iconAppleDesktop} alt="google icon" className='no-border-image' /><span>Conitnue with Apple</span></button>
                            {/* <button onClick={toggleDivs} className="login-button-desktop"><img src={iconMailDesktop} alt="google icon" className='no-border-image' /><span>Conitnue with Email</span></button> */}
                        </div>
                    )}


                </div>
            ) : (
                <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start", padding: "20px" }}>
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                        <img onClick={toggleDivs} src={iconBack} alt="back icon" className='no-border-image' />
                        <img onClick={onToggle} src={iconClose} alt="close icon" className='no-border-image' />
                    </div>



                    {isLoginDivVisible ? (
                        <div style={{ width: "550px", display: "flex", flexDirection: "column", gap: "10px", alignItems: "start" }}>
                            <p className='history-archive-text' style={{ marginTop: "10px" }}>Log in</p>
                            <p className='history-archive-text' style={{ textAlign: "center", fontSize: "20px", fontWeight: "500", marginBottom: "40px" }}>
                                Don't have an account? <span onClick={toggleLoginDivs} className='span-first-gradient' style={{ fontWeight: '500', textDecoration: "none" }}>Sign up</span>
                            </p>
                        </div>
                    ) : (
                        <div style={{ width: "550px", display: "flex", flexDirection: "column", gap: "10px", alignItems: "start" }}>
                            <p className='history-archive-text' style={{ marginTop: "10px" }}>Sign up</p>
                            <p className='history-archive-text' style={{ textAlign: "center", fontSize: "20px", fontWeight: "500", marginBottom: "40px" }}>
                                Already have an account? <span onClick={toggleLoginDivs} className='span-first-gradient' style={{ fontWeight: '500', textDecoration: "none" }}>Log in</span>
                            </p>
                        </div>
                    )}

                    {isLoginDivVisible ? (
                        <div style={{ width: "550px", display: "flex", flexDirection: "column", gap: "10px" }}>
                            <input type="text" value={email} onChange={handleEmailChange} placeholder="Email" className="sign-up-input" />
                            <div style={{ position: 'relative' }}>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={handlePasswordChange}
                                    placeholder="Password"
                                    className="sign-up-input"
                                />
                                <img
                                    src={iconEye}
                                    alt="Toggle password visibility"
                                    onClick={togglePasswordVisibility}
                                    style={{
                                        position: 'absolute',
                                        cursor: 'pointer',
                                        right: '20px', // Adjust the position as needed
                                        top: '50%',
                                        transform: 'translateY(-50%)'
                                    }}
                                />
                            </div>
                            <button onClick={handleLoginClick} className="App-button" style={{ "width": "100%" }}>Log in</button>
                            {errorMessage && <span className="error-message">{errorMessage}</span>}
                        </div>
                    ) : (
                        <div style={{ width: "550px", display: "flex", flexDirection: "column", gap: "10px" }}>
                            <div style={{ width: "550px", display: "flex", flexDirection: "row", gap: "10px" }}>
                                <input type="text" value={emailSignUp} onChange={handleEmailSignUpChange} placeholder="Email" className="sign-up-input" />
                                <input type="text" value={verificationSignUp} onChange={handleVerificationSignUpChange} placeholder="Verification Code (optional)" className="sign-up-input" />
                            </div>

                            <div style={{ position: 'relative' }}>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    value={passwordSignUp}
                                    onChange={handlePasswordSignUpChange}
                                    placeholder="Set password 6 characters minimum"
                                    className="sign-up-input"
                                />
                                <img
                                    src={iconEye}
                                    alt="Toggle password visibility"
                                    onClick={togglePasswordVisibility}
                                    style={{
                                        position: 'absolute',
                                        cursor: 'pointer',
                                        right: '20px', // Adjust the position as needed
                                        top: '50%',
                                        transform: 'translateY(-50%)'
                                    }}
                                />
                            </div>
                            <button onClick={handleSignUpClick} className="App-button" style={{ "width": "100%" }}>Sign up</button>
                            {errorMessage && <span className="error-message">{errorMessage}</span>}
                        </div>
                    )}

                </div>
            )}

        </div>
    );
}

// Utility function to generate a random string for the state parameter
function generateRandomString(length = 32) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

export default SignUp;