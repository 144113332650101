import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import './css/App.css';
import './css/account.css';
import './css/success.css';
import './css/logged-in.css';

import iconUser from './assets/images/user-square.png';
import iconBack from './assets/images/icon-back.png';
import { fetchUserData } from './utils';
import { useResponsiveWrapperVisibility } from './utils';

function Account() {
    const [userEmail, setUserEmail] = useState(null);
    const [userStatus, setUserStatus] = useState(null);
    const public_stripe_key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    const selectedOption = 0;

    useEffect(() => {
        fetchUserData(() => { }, setUserEmail, setUserStatus, () => { });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleSubscription = async () => {

        if (userStatus !== 'active' && userStatus !== 'canceled') {
            
            navigate('/paywall');

        } else {

            const stripePromise = await loadStripe(public_stripe_key);
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/create-stripe-session`, {
                method: "POST",
                headers: { "Content-Type": "Application/JSON" },
                body: JSON.stringify({ selectedOption, userEmail })
            });

            if (response.status === 409) {
                const data = await response.json();
                if (data && data.redirectUrl) {
                    window.location.href = data.redirectUrl; // redirect to billing portal if user is already subscribed
                }
            } else {
                const session = await response.json();
                stripePromise.redirectToCheckout({
                    sessionId: session.id,
                });
            }

        }
    };

    const getStatusText = (status) => {
        if (status === 'active') {
            return 'Premium';
        } else if (status === 'canceled') {
            return 'Canceled';
        } else {
            return 'Free';
        }
    };

    const navigate = useNavigate();

    const handleLogout = () => {
        sessionStorage.removeItem('session_token');
        navigate('/');
    };

    const isWrapperVisible = useResponsiveWrapperVisibility();

    return (
        <div className={`App ${isWrapperVisible ? 'App-banner-visible' : ''}`} style={{ justifyContent: 'flex-start' }}>
            <div className='div-top-bottom'>
                <div style={{ width: "100%", display: "flex", justifyContent: 'start', marginTop: "20px" }}>
                    <Link to="/" style={{ "width": "auto" }}>
                        <div className='top-icons'><img src={iconBack} alt="back icon" /></div>
                    </Link>
                </div>
            </div>
            <div className='profile-wrapper'>
                <div className='profile-item profile-circle' >
                    <img src={iconUser} alt="User Icon" style={{ width: "16px", height: "16px" }} />
                </div>

                <p id='user-email-from-server' style={{ height: userEmail ? 'auto' : '20px' }}>
                    {userEmail}
                </p>

                <div className='profile-item' style={{ width: "100%", gap: "8px", marginBottom: "10px" }}>
                    <div style={{ "width": "100%", display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                        <p className='profile-text'>Account status</p>
                        <p className='profile-text'>{getStatusText(userStatus)}</p>
                    </div>
                    <div style={{ "width": "100%", display: 'flex', flexDirection: "row", justifyContent: "end" }}>
                        <span onClick={() => handleSubscription()} className='success-email-gradient' style={{ color: "#3E8BFF", textAlign: "end" }}>Update</span>
                    </div>
                </div>

                {/* {userStatus === 'active' || userStatus === 'canceled' ? (
                    <>
                        <div className='profile-item' onClick={() => navigate('/success')} style={{ borderRadius: "16px 16px 0px 0px", borderBottom: "none", height: "auto" }}>
                            <span style={{ cursor: "pointer" }}>App Activation</span>
                        </div>
                    </>
                ) : (
                    <>

                    </>
                )} */}

                <div style={{ display: 'flex', flexDirection: "column", alignItems: "center", width: "100%" }}>
                    {/* <div className='profile-item' style={{ borderRadius: userStatus === 'active' ? '0px' : '16px 16px 0px 0px', borderBottom: "none", height: "auto" }}> */}
                    <div className='profile-item' style={{ borderRadius: '16px 16px 0px 0px', borderBottom: "none", height: "auto" }}>
                        <a href="/privacy" target="_blank" rel="noreferrer" className='profile-links'>Privacy policy</a>
                    </div>
                    <div className='profile-item' style={{ borderRadius: "0px", borderBottom: "none", height: "auto" }}>
                        <a href="/terms" target="_blank" rel="noreferrer" className='profile-links'>Terms of use</a>
                    </div>
                    <div className='profile-item' style={{ borderRadius: "0px", borderBottom: "none", height: "auto" }}>
                        <a href="mailto:social.apps.ai@outlook.com" className='profile-links'>Contact support</a>
                    </div>
                    <div className='profile-item' onClick={handleLogout} style={{ borderRadius: "0px 0px 16px 16px", height: "auto" }}>
                        <span style={{ cursor: "pointer" }}>Log out</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Account;