import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './css/App.css';
import './css/logged-in.css';
import './css/comming-soon.css';
import iconClose from './assets/images/close.png';
import commingSoon from './assets/images/comming-soon.png';
import { useResponsiveWrapperVisibility } from './utils';
const isAndroid = () => {
    return /Android/i.test(navigator.userAgent);
};

function CommingSoonMobile() {
    const desktopWidth = 768;
    const [isMobile, setIsMobile] = useState(window.innerWidth < desktopWidth);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < desktopWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleButtonClick = () => {
        const returnLocation = sessionStorage.getItem('return_location');
        if (returnLocation === 'create') {
            navigate('/create');
        } else {
            navigate('/');
        }
    };

    const isWrapperVisible = useResponsiveWrapperVisibility();
    const [isAndroidDevice, setIsAndroidDevice] = useState(false);

    useEffect(() => {
        setIsAndroidDevice(isAndroid());
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={`App ${isWrapperVisible ? 'App-banner-visible' : ''} ${isAndroidDevice ? 'android-device' : ''}`}>

            <div className='div-top-bottom' style={{ width: isMobile ? '100%' : '800px' }}>
                <div style={{ width: "100%", display: "flex", justifyContent: 'flex-end' }}>
                    <Link to="/" style={{ "width": "auto" }}>
                        <div className='top-icons' style={{ padding: '20px' }}><img src={iconClose} alt="close icon" style={{ width: '28px', height: 'auto' }} /></div>
                    </Link>
                </div>
            </div>

            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly", width: isMobile ? '100%' : '700px', height: "100%" }}>

                <img src={commingSoon} style={{ marginTop: "15px" }} alt="wrenches" />

                <div className='comming-text'><p>Coming soon...</p></div>
                <div className='comming-text-small'>
                    <p>It seems like you’ve upload a face/profile of a male. We are still working on introduction of this function to process male faces/profiles</p>
                </div>


            </div>

            <div style={{ width: isMobile ? '100%' : '800px', display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                <div className='first-footer' style={{ width: "100%", marginBottom: "0px" }}>
                    <button
                        className='App-button'
                        onClick={handleButtonClick}

                        style={{ width: "100%" }}>
                        <div className='button-row'>
                            <p style={{ lineHeight: "1" }}>Continue</p>
                        </div>
                    </button>

                </div>
                <div className='footer-sh-create' style={{ width: "100%" }}>
                    <div style={{ height: '75px' }}></div>
                </div>
            </div>


        </div>

    );

}

export default CommingSoonMobile;