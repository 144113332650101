import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import heic2any from "heic2any";
import './css/App.css';
import './css/logged-in.css';
import './css/got-it.css';
import iconClose from './assets/images/close.png';
import gotItFemaleCreate1 from './assets/images/got-it-create-1.png';
import gotItFemaleCreate2 from './assets/images/got-it-create-2.png';
import iconGotItCheck from './assets/images/icon-check-green.png';
import iconGotItClose from './assets/images/icon-close-red.png';
import { useResponsiveWrapperVisibility } from './utils';
import { fetchUserData } from './utils';

function GotItCreate({ onToggleClose }) {

    const [userId, setUserId] = useState(null);

    useEffect(() => {
        fetchUserData(() => { }, () => { }, () => { }, setUserId);
    }, []);

    const [isUploading, setIsUploading] = useState(false);
    const navigate = useNavigate();
    const hiddenFileInput = useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }

        setIsUploading(true); // Disable the button and change the caption

        const fileExtension = file.name.split('.').pop().toLowerCase();
        // console.log("Extracted extension: " + fileExtension);
        // Check if the file extension is HEIC or HEIF
        if (fileExtension === 'heic' || fileExtension === 'heif') {
            // console.log('File is HEIC or HEIF, converting to JPEG...');
            convertHEIFtoJPEG(file);
        } else {
            // console.log('File is not HEIC or HEIF, uploading original file...');
            uploadFile(file);
        }
    };

    const convertHEIFtoJPEG = async (file) => {
        try {
            const convertedBlob = await heic2any({
                blob: file,
                toType: "image/jpeg",
                quality: 0.8 // Adjust the quality as needed
            });
            // console.log('Conversion successful, uploading...');
            // Create a new file object from the converted blob with a .jpg extension
            const jpegFile = new File([convertedBlob], 'upload.jpg', {
                type: 'image/jpeg',
                lastModified: new Date().getTime()
            });
            // console.log(`Uploading file: ${jpegFile.name}`);
            uploadFile(jpegFile);
        } catch (error) {
            console.error('Error converting image:', error);
        }
    };

    const uploadFile = (file) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('user', 'test');

        fetch(`/api/upload`, { // Replace with your actual endpoint
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                if (data.url) {
                    localStorage.setItem('uploadedFileUrlCreate', data.url);
                    navigate('/loading-create', { state: { imageUrl: data.url, userId: userId } });
                } else {
                    localStorage.setItem("LoadingError", "Failed to upload an image... ");
                    navigate('/?toggleDivVisibilityError=true');
                    throw new Error('File path not provided in response.');
                }
            })
            .catch(error => {
                console.error('Upload error:', error);
                setIsUploading(false); // Re-enable the button if upload fails
            });
    };

    const handleUploadClick = () => {
        hiddenFileInput.current.click();
    };

    const isWrapperVisible = useResponsiveWrapperVisibility();

    return (
        <div className='got-it-wrapper' style={{ marginTop: isWrapperVisible ? '110px' : '' }}>

            <div className='div-top-bottom' style={{ width: "100%" }}>
                <div style={{ width: "100%", display: "flex", justifyContent: 'end' }}>
                    {/* <Link to="/loading" style={{ "width": "auto" }}> */}
                    <div className='top-icons'><img onClick={onToggleClose} src={iconClose} alt="close icon" /></div>
                    {/* </Link> */}
                </div>
            </div>

            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly", weight: "100%", height: "100%" }}>
                <div className="got-it-check-close">
                    <div className='got-it-item'>
                        <div className='top-icons' style={{ marginTop: "10px" }}><img src={iconGotItCheck} alt="check icon" /></div>
                        <img src={gotItFemaleCreate2} style={{ "width": "100%", borderRadius: "10px", marginTop: "15px" }} alt="female 1" />
                    </div>
                    <div className='got-it-item'>
                        <div className='top-icons' style={{ marginTop: "10px" }}><img src={iconGotItClose} alt="close icon" /></div>
                        <img src={gotItFemaleCreate1} style={{ "width": "100%", borderRadius: "10px", marginTop: "15px" }} alt="female 2" />
                    </div>
                </div>

                <div className='got-it-text'>
                    <p>Make sure to upload an image that</p>
                    <p>has visible bio of dating profile</p>
                    <p>and not photo of a person</p>
                </div>
            </div>

            <div className='first-footer' style={{ width: "100%", marginBottom: "0px" }}>
                <button
                    className={isUploading ? 'button-disabled' : 'App-button'}
                    onClick={handleUploadClick}
                    disabled={isUploading}
                    style={{ width: "100%" }}>
                    <div className='button-row'>
                        {isUploading ? <p style={{ lineHeight: "1" }}>Uploading...</p> : <p style={{ lineHeight: "1" }}>Got it 👌</p>}
                    </div>
                </button>
                <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    disabled={isUploading}
                />
            </div>

        </div>

    );

}

export default GotItCreate;