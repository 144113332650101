import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import './css/App.css';

import iconUpload from './assets/images/upload-icon.png';
import iconInfo from './assets/images/info-circle.png';
import femaleLeft from './assets/images/first-left.png';
import femaleRight from './assets/images/first-right.png';
import femaleCenter from './assets/images/first-main.png';
import imagePayWallFemale from './assets/images/paywall-female.png';
import iconClose from './assets/images/close.png';
import iconV from './assets/images/iconv.png';
import iconLock from './assets/images/lock.png';
import iconInfoWhite from './assets/images/info-white.png';
import iconSetting from './assets/images/setting.png';
import iconGoogle from './assets/images/icon-google.png';
import iconMail from './assets/images/icon-email.png';
import iconApple from './assets/images/icon-apple.png';
import loggedHistory from './assets/images/logged-history.png';
import iconUser from './assets/images/user-square.png';
import iconBack from './assets/images/icon-back.png';
import iconSettingWhite from './assets/images/setting-white.png';
import iconArchive from './assets/images/archive-plus.png';
import gotItFemale1 from './assets/images/got-it-1.png';
import gotItFemale2 from './assets/images/got-it-2.png';
import iconGotItCheck from './assets/images/icon-check-green.png';
import iconGotItClose from './assets/images/icon-close-red.png';
import iconResultBack from './assets/images/icon-result-back.png';
import iconResultFace from './assets/images/icon-result-face.png';
import iconResultNose from './assets/images/icon-result-nose.png';
import iconResultLips from './assets/images/icon-result-back.png';
import iconResultEye from './assets/images/icon-result-eye.png';
import iconResultFire from './assets/images/icon-result-fire.png';
import infoResultCircle from './assets/images/info-result-circle.png';
import resultFemale from './assets/images/result-female.png';
import iconUnlock from './assets/images/unlock.png';
import iconCreate from './assets/images/icon-create.png';
import iconCreateRed from './assets/images/icon-create-red.png';
import iconAnalyze from './assets/images/icon-analyze.png';
import iconAnalyzeRed from './assets/images/icon-analyze-red.png';
import iconHistory from './assets/images/icon-history.png';
import iconHistoryRed from './assets/images/icon-history-red.png';
import phone1 from './assets/images/phone1.png';
import phone2 from './assets/images/phone2.png';
import toggle1 from './assets/images/toggle1.png';
import toggle2 from './assets/images/toggle2.png';
import createBackground1 from './assets/images/create-background1.png';
import createBackground2 from './assets/images/create-background2.png';
import gotItFemaleCreate1 from './assets/images/got-it-create-1.png';
import gotItFemaleCreate2 from './assets/images/got-it-create-2.png';
import gotItFemaleCreate11 from './assets/images/got-it-create-11.png';
import gotItFemaleCreate12 from './assets/images/got-it-create-12.png';
import femaleSignUpDesktop from './assets/images/female-sign-up-desktop.png';

import Paywall from './paywall';
import Success from './success';
import Login from './login';
import Account from './account';
import History from './history';
import LoadingCreate from './loading-create';
import Loading from './loading';
import Result from './result';
import NotFound from './NotFound';
import Create from './create';
import GotItMobile from './got-it-mobile';
import GotItCreateMobile1 from './got-it-create-mobile1';
import GotItCreateMobile2 from './got-it-create-mobile2';
import CommingSoonMobile from './comming-soon-mobile';
import OAuthRedirect from './oauth-redirect';
import AppleRedirect from './apple-redirect';
import ErrorMobile from './error-mobile';
import ErrorMobileCreate from './error-mobile-create';

import SignUp from './sign-up';
import { fetchUserDataLogin } from './utils';
import { useResponsiveWrapperVisibility } from './utils';
import TabBar from './tabbar';
import GotIt from './got-it';
import CommingSoon from './comming-soon';
import ResultCreate from './result-create';
import Error from './error';

const isAndroid = () => {
  return /Android/i.test(navigator.userAgent);
};

function App() {
  const desktopWidth = 768;
  const [isMobile, setIsMobile] = useState(window.innerWidth < desktopWidth);
  const imageUrls = [
    imagePayWallFemale, iconClose, iconV, iconLock, iconInfoWhite, iconSetting,
    iconGoogle, iconMail, iconApple, loggedHistory, iconUser, iconBack,
    iconSettingWhite, iconArchive, gotItFemale1, gotItFemale2, iconGotItCheck, iconGotItClose,
    iconResultBack, iconResultFace, iconResultNose, iconResultLips, iconResultEye, iconResultFire,
    infoResultCircle, resultFemale, iconUnlock, iconCreate, iconCreateRed, iconHistory, iconHistoryRed,
    iconAnalyze, iconAnalyzeRed, phone1, phone2, createBackground1, createBackground2, toggle1, toggle2,
    gotItFemaleCreate1, gotItFemaleCreate2, gotItFemaleCreate12, gotItFemaleCreate11, femaleSignUpDesktop
  ];

  const location = useLocation();

  function preloadImages(urls) {
    urls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  }

  useEffect(() => {
    preloadImages(imageUrls);
    const handleResize = () => {
      setIsMobile(window.innerWidth < desktopWidth);
    };

    // Detect if the platform is Mac using userAgent and add the 'mac-os' class to the body
    if (navigator.userAgent.toUpperCase().indexOf('MAC') >= 0) {
      document.body.classList.add('mac-os');
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [isDivVisible, setIsDivVisible] = useState(false);

  const toggleDivVisibility = () => {
    setIsDivVisible(!isDivVisible);
  };

  const [isDivVisibleGotIt, setIsDivVisibleGotIt] = useState(false);

  const toggleDivVisibilityGotIt = () => {
    setIsDivVisibleGotIt(!isDivVisibleGotIt);
  };

  const [isDivVisibleComming, setIsDivVisibleComming] = useState(false);

  const toggleDivVisibilityComming = () => {
    setIsDivVisibleGotIt(false);
    setIsDivVisibleError(false);
    setIsDivVisibleComming(!isDivVisibleComming);
  };

  const [isDivVisibleError, setIsDivVisibleError] = useState(false);

  const toggleDivVisibilityError = () => {
    setIsDivVisibleGotIt(false);
    setIsDivVisibleComming(false);
    setIsDivVisibleError(!isDivVisibleError);
  };

  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserDataLogin(() => { }, () => { }, () => { }, setUserId);
  }, [location, navigate]);

  useEffect(() => {

    setIsDivVisibleGotIt(false);
    setIsDivVisibleError(false);
    setIsDivVisibleComming(false);

    const params = new URLSearchParams(location.search);
    if (params.get('toggleDivVisibilityComming') === 'true') {
      toggleDivVisibilityComming();
    }

    if (params.get('toggleDivVisibilityError') === 'true') {
      toggleDivVisibilityError();
    }

  }, [location, navigate]);

  const handleContinue = () => {
    sessionStorage.setItem('return_location', "analyze");
    localStorage.removeItem("LoadingError");
    if (userId != null) {
      if (isMobile) {
        navigate('/got-it-mobile');
      } else {
        toggleDivVisibilityGotIt();
      }
    } else {
      if (isMobile) {
        navigate('/login');
      } else {
        toggleDivVisibility();
      }
    }
  };

  const handleAccountClick = () => {
    sessionStorage.setItem('return_location', "account");
    if (userId != null) {
      navigate('/account');
    } else {
      if (isMobile) {
        navigate('/login');
      } else {
        toggleDivVisibility();
      }
    }
  };

  const isWrapperVisible = useResponsiveWrapperVisibility();

  const [isAndroidDevice, setIsAndroidDevice] = useState(false);

    useEffect(() => {
        setIsAndroidDevice(isAndroid());
        window.scrollTo(0, 0);
    }, []);

  return (
    // <Router>
    <Routes>
      <Route path="/" element={
        <div className={`App ${isWrapperVisible ? 'App-banner-visible' : ''} ${isAndroidDevice ? 'android-device' : ''}`}>

          <div className='div-top-bottom'>

            <div className='top-icon-wrapper'>

              {/* <Link to="/account" style={{ "width": "auto" }}> */}
              <div className='top-icons'><img src={iconUser} alt="icon user" onClick={handleAccountClick} /></div>
              {/* </Link> */}

            </div>

          </div>

          {/* <div className={`gradient-image-wrapper ${isDivVisible ? 'blur-effect' : ''}`}> */}
          <div className={`gradient-image-wrapper ${isDivVisible ? 'blur-effect' : ''}`}>
            <div className='two-image-tilt'>
              <img src={femaleLeft} alt="female" className='side-images app-image-small1' />
              <img src={femaleRight} alt="female" className='side-images app-image-small1' />
              <img src={femaleCenter} alt="female" className="center-image app-image-small2" />

            </div>
            <div className="background-gradient"></div>

          </div>

          <div className='first-text-wrapper'>

            {isMobile ? (
              <>
                <div className='app-analyze-text'>
                  <p>
                    Analyze interests, character<br />
                    and hobbies of your crush<br />
                    using photo and AI
                  </p>
                </div>
                <div className='app-get-detailed-text'>
                  <p className='add-opacity'>
                    Get detailed report that would<br />
                    help you to rizz a girl
                  </p>
                </div>
              </>
            ) : (
              <>
                <div style={{ marginBottom: "24px" }}>
                  <p style={{ fontSize: "32px" }}>
                    Analyze interests, character and hobbies<br />
                    of your crush using photo and AI
                  </p>
                </div>
                <div>
                  <p className='add-opacity'>Get detailed report that would help you to rizz a girl</p>
                </div>
              </>
            )}
          </div>

          <div style={{ width: '100%', display: "flex", flexDirection: 'column', alignItems: 'center' }}>
            <div className='info-box-wrapper'>
              <div className='first-info-box'>
                <p className='add-opacity'>Upload a photo or a girl from any{isMobile && <br />}social media or dating app</p>
                <img src={iconInfo} alt="Info Icon" />
              </div>
            </div>


            <div className={`first-footer footer-sh ${isDivVisible ? 'blur-effect' : ''}`}>
              <button onClick={handleContinue} className="App-button">
                <div className='button-row'>
                  <p style={{ lineHeight: "1" }}>Upload</p>
                  <img src={iconUpload} alt="upload icon" />
                </div>
              </button>

            </div>
            <div className='footer-sh' style={{ width: "100%" }}>
              <TabBar />
            </div>
          </div>
          {isDivVisible && (
            <div className="sign-up-div">
              <SignUp onToggle={toggleDivVisibility} />
            </div>
          )}

          {isDivVisibleGotIt && (
            <div className="sign-up-div">
              <GotIt onToggle={toggleDivVisibilityGotIt} />
            </div>
          )}

          {isDivVisibleComming && (
            <div className="sign-up-div">
              <CommingSoon onToggle={toggleDivVisibilityComming} />
            </div>
          )}

          {isDivVisibleError && (
            <div className="sign-up-div">
              <Error onToggle={toggleDivVisibilityError} />
            </div>
          )}
        </div>
      } />
      <Route path="/account" element={<Account />} />
      <Route path="/history" element={<History />} />
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/loading-create" element={<LoadingCreate />} />
      <Route path="/loading" element={<Loading />} />
      <Route path="/result" element={<Result />} />
      <Route path="/paywall" element={<Paywall />} />
      <Route path="/success" element={<Success />} />
      <Route path="/create" element={<Create />} />
      <Route path="/result-create" element={<ResultCreate />} />
      <Route path="/got-it-mobile" element={<GotItMobile />} />
      <Route path="/got-it-create-mobile1" element={<GotItCreateMobile1 />} />
      <Route path="/got-it-create-mobile2" element={<GotItCreateMobile2 />} />
      <Route path="/comming-soon-mobile" element={<CommingSoonMobile />} />
      <Route path="/oauth-redirect" element={<OAuthRedirect />} />
      <Route path="/apple-redirect" element={<AppleRedirect />} />
      <Route path="/error-mobile" element={<ErrorMobile />} />
      <Route path="/error-mobile-create" element={<ErrorMobileCreate />} />
    </Routes>
    // </Router >
  );
}

export default App;