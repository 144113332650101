import React from 'react';
import { NavLink } from 'react-router-dom';
import iconCreate from './assets/images/icon-create.png';
import iconCreateRed from './assets/images/icon-create-red.png';
import iconAnalyze from './assets/images/icon-analyze.png';
import iconAnalyzeRed from './assets/images/icon-analyze-red.png';
import iconHistory from './assets/images/icon-history.png';
import iconHistoryRed from './assets/images/icon-history-red.png';
import './css/tabbar.css';

const TabBar = () => {
    return (
        <div className="tab-bar">
            <div className="tab-bar-wrap">
                <NavLink to="/create" className={({ isActive }) => isActive ? "tab active" : "tab"}>
                    {({ isActive }) => (
                        <div className="tab-content">
                            <img src={isActive ? iconCreateRed : iconCreate} alt="Create" />
                            <span className={isActive ? 'active-text' : ''}>Create</span>
                        </div>
                    )}
                </NavLink>
                <NavLink to="/" className={({ isActive }) => isActive ? "tab active" : "tab"}>
                    {({ isActive }) => (
                        <div className="tab-content">
                            <img src={isActive ? iconAnalyzeRed : iconAnalyze} alt="Analyze" />
                            <span className={isActive ? 'active-text' : ''}>Analyze</span>
                        </div>
                    )}
                </NavLink>
                <NavLink to="/history" className={({ isActive }) => isActive ? "tab active" : "tab"}>
                    {({ isActive }) => (
                        <div className="tab-content">
                            <img src={isActive ? iconHistoryRed : iconHistory} alt="History" />
                            <span className={isActive ? 'active-text' : ''}>History</span>
                        </div>
                    )}
                </NavLink>
            </div>
        </div>
    );
};

export default TabBar;