import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import iconUnlock from './assets/images/unlock.png';
import { useResponsiveWrapperVisibility } from './utils';

import './css/history-item-view.css';
import './css/history.css';

const HistoryItemView = ({ item, onClose, userStatus }) => {

    const filteredMessages = item.messages.filter(msg => msg.history === 1);
    const sortedMessages = [...filteredMessages].sort((a, b) => b.date - a.date);
    const [copiedMessageIndex, setCopiedMessageIndex] = useState(null);
    const navigate = useNavigate();
    const desktopWidth = 768;
    const [isMobile, setIsMobile] = useState(window.innerWidth < desktopWidth);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < desktopWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // Disable right-click
        const handleContextMenu = (e) => {
            e.preventDefault();
        };
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    const handleCopyMessage = (msg, index) => {
        if (userStatus !== 'active') return;

        navigator.clipboard.writeText(msg.message).then(() => {
            setCopiedMessageIndex(index);
            setTimeout(() => {
                setCopiedMessageIndex(null);
            }, 1000); // Show "Copied!" for 1 second
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    };

    const isWrapperVisible = useResponsiveWrapperVisibility();

    return (
        <div className="history-item-view" style={{ userSelect: 'none', marginTop: isWrapperVisible ? '110px' : '', paddingBottom: isWrapperVisible ? '110px' : '', paddingLeft: isMobile ? '10px' : '', paddingRight: isMobile ? '10px' : '' }}>
            <div className="history-item-view-content scrollable-div">
                <button className="close-button" style={{ top: isWrapperVisible ? '110px' : '10px' }} onClick={onClose}>Close</button>
                <img src={item.image} alt="History" className="history-item-view-image" style={{ marginBottom: "20px" }} />
                <div className="history-item-view-messages">
                    {sortedMessages.map((msg, index) => (
                        <div
                            key={index}
                            className="history-item-view-message"
                            onClick={() => handleCopyMessage(msg, index)}
                            style={{ cursor: userStatus !== 'active' ? 'default' : 'pointer' }}
                        >
                            <span className={userStatus !== 'active' ? 'blurred' : ''}>
                                {copiedMessageIndex === index ? 'Copied!' : msg.message}
                            </span>
                            {index === 0 && userStatus !== 'active' && (
                                <button
                                    className="login-button info-box-button history-paywall-button overlay-button"
                                    onClick={() => navigate('/paywall')}
                                    style={{ border: '1px solid black' }}
                                >
                                    <div className='button-row'>
                                        <img src={iconUnlock} alt="unlock icon" />
                                        <p style={{ lineHeight: "1" }}>Reveal</p>
                                    </div>
                                </button>
                            )}
                        </div>
                    ))}

                </div>

            </div>

        </div>
    );
};

export default HistoryItemView;