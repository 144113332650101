import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './css/App.css';
import './css/loading.css';

function LoadingCreate() {
    const desktopWidth = 768;
    const [isMobile, setIsMobile] = useState(window.innerWidth < desktopWidth);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < desktopWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const navigate = useNavigate();
    const location = useLocation();
    const { imageUrl, userId } = location.state;
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prev) => (prev < 100 ? prev + 1 : 100));
        }, 100); // Adjust the interval as needed

        fetch(`${process.env.REACT_APP_SERVER_URL}/img-id`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId: userId,
                image: imageUrl
            })
        })
            .then(response => response.json())
            .then(responseData => {
                clearInterval(interval);
                if (responseData.gender === 'female' || responseData.gender === 'male') {
                    navigate('/result-create');
                // } else if (responseData.gender === 'male') {
                //     if (isMobile) {
                //         navigate('/comming-soon-mobile');
                //     } else {
                //         navigate('/?toggleDivVisibilityComming=true');
                //     }

                } else {
                    localStorage.setItem("LoadingError", responseData.gender);
                    if (isMobile) {
                        navigate('/error-mobile');
                    } else {
                        navigate('/create/?toggleDivVisibilityError=true');
                    }
                }
            })
            .catch(error => {
                clearInterval(interval);
                console.error('Error saving image ID:', error);
                localStorage.setItem("LoadingError", error);
                if (isMobile) {
                    navigate('/error-mobile');
                } else {
                    navigate('/create/?toggleDivVisibilityError=true');
                }
            });

        return () => clearInterval(interval);
    }, [imageUrl, userId, navigate]);

    return (
        <div className="App App-center">
            <p className='loading-text gradient-text-percent'>{progress}%</p>
            <p className='loading-text loading-dots'>Analyzing<span>.</span><span>.</span><span>.</span></p>
        </div>
    );
}

export default LoadingCreate;