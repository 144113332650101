import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './css/App.css';
import './css/create.css';

import iconUpload from './assets/images/upload-icon.png';
import phone1 from './assets/images/phone1.png';
import phone2 from './assets/images/phone2.png';
import createBackground1 from './assets/images/create-background1.png';
import createBackground2 from './assets/images/create-background2.png';
import iconUser from './assets/images/user-square.png';

import SignUp from './sign-up';
import { fetchUserDataLogin } from './utils';
import { useResponsiveWrapperVisibility } from './utils';
import TabBar from './tabbar';
import GotItCreate from './got-it-create';
import GotItCreate1 from './got-it-create1';
import CommingSoon from './comming-soon';
import Toggle from './Toggle';
import Error from './error';

const isAndroid = () => {
    return /Android/i.test(navigator.userAgent);
};

function Create() {
    const desktopWidth = 768;
    const mobileWidth = 400;
    const [isMobile, setIsMobile] = useState(window.innerWidth < desktopWidth);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < mobileWidth);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < desktopWidth);
            setIsSmallScreen(window.innerWidth < mobileWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [isDivVisible, setIsDivVisible] = useState(false);

    const toggleDivVisibility = () => {
        setIsDivVisible(!isDivVisible);
    };

    const [isDivVisibleGotItCreate, setIsDivVisibleGotItCreate] = useState(false);
    const [isDivVisibleGotItCreate1, setIsDivVisibleGotItCreate1] = useState(false);

    const toggleDivVisibilityGotItCreate = () => {
        setIsDivVisibleGotItCreate(!isDivVisibleGotItCreate);
    };

    const toggleDivVisibilityGotItCreate1 = () => {
        setIsDivVisibleGotItCreate1(!isDivVisibleGotItCreate1);
    };

    const [isDivVisibleComming, setIsDivVisibleComming] = useState(false);

    const toggleDivVisibilityComming = () => {
        setIsDivVisibleGotItCreate(false);
        setIsDivVisibleGotItCreate1(false);
        setIsDivVisibleError(false);
        setIsDivVisibleComming(!isDivVisibleComming);
    };

    const [isDivVisibleError, setIsDivVisibleError] = useState(false);

    const toggleDivVisibilityError = () => {
        setIsDivVisibleGotItCreate(false);
        setIsDivVisibleGotItCreate1(false);
        setIsDivVisibleComming(false);
        setIsDivVisibleError(!isDivVisibleError);
    };

    const location = useLocation();
    const [userId, setUserId] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserDataLogin(() => { }, () => { }, () => { }, setUserId);
    }, [location, navigate]);

    useEffect(() => {

        setIsDivVisibleGotItCreate(false);
        setIsDivVisibleGotItCreate1(false);
        setIsDivVisibleComming(false);
        setIsDivVisibleError(false);

        const params = new URLSearchParams(location.search);
        if (params.get('toggleDivVisibilityComming') === 'true') {
            toggleDivVisibilityComming();
        }

        if (params.get('toggleDivVisibilityError') === 'true') {
            toggleDivVisibilityError();
        }

    }, [location]);

    const handleContinue = () => {
        sessionStorage.setItem('return_location', "create");
        if (userId != null) {
            if (isMobile) {
                if (isToggled) {
                    navigate('/got-it-create-mobile1');
                } else {
                    navigate('/got-it-create-mobile2');
                }
            } else {
                if (isToggled) {
                    toggleDivVisibilityGotItCreate1();
                } else {
                    toggleDivVisibilityGotItCreate();
                }
            }
        } else {
            if (isMobile) {
                navigate('/login');
            } else {
                toggleDivVisibility();
            }
        }
    };

    const handleAccountClick = () => {
        sessionStorage.setItem('return_location', "account");
        if (userId != null) {
            navigate('/account');
        } else {
            if (isMobile) {
                navigate('/login');
            } else {
                toggleDivVisibility();
            }
        }
    };

    const [isToggled, setIsToggled] = useState(false);

    const handleToggleChange = (newState) => {
        setIsToggled(newState);
    };

    const isWrapperVisible = useResponsiveWrapperVisibility();

    const [isAndroidDevice, setIsAndroidDevice] = useState(false);

    useEffect(() => {
        setIsAndroidDevice(isAndroid());
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={`App ${isWrapperVisible ? 'App-banner-visible' : ''} ${isAndroidDevice ? 'android-device' : ''}`}>

            <div className='div-top-bottom'>

                <div className='top-icon-wrapper'>

                    {/* <Link to="/account" style={{ "width": "auto" }}> */}
                    <div className='top-icons'><img src={iconUser} alt="icon user" onClick={handleAccountClick} /></div>
                    {/* </Link> */}

                </div>

            </div>

            <div className={`gradient-image-wrapper-create ${isDivVisible ? 'blur-effect' : ''}`}>
                <div className="background-gradient-create"></div>
                <div className="image-container">
                    <img src={isToggled ? createBackground1 : createBackground2} alt="background" id="center-image" className={`background-image ${isToggled ? 'phone1-background' : 'phone2-background'}`} />
                    <img src={isToggled ? phone1 : phone2} alt="phone" className={`${isToggled ? 'phone1' : 'phone2'}`} />
                </div>
            </div>

            <Toggle onChange={handleToggleChange} />

            <div className='first-text-wrapper-create'>

                {isMobile ? (
                    <>
                        <div>
                            <p style={{ fontSize: isSmallScreen ? "20px" : "24px" }}>
                                Upload an image of <span className="p-gradient-background">{isToggled ? "your chat" : "dating profile"}</span><br />
                                to get witty answers
                            </p>
                        </div>

                    </>
                ) : (
                    <>
                        <div style={{ marginBottom: "24px" }}>
                            <p style={{ fontSize: "32px" }}>
                                Upload an image of <span className="p-gradient-background">{isToggled ? "your chat" : "dating profile"}</span><br />
                                to get witty answers
                            </p>
                        </div>

                    </>
                )}
            </div>

            <div style={{ width: '100%', display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                <div className={`first-footer footer-sh-create ${isDivVisible ? 'blur-effect' : ''}`}>
                    <button onClick={handleContinue} className="App-button">
                        <div className='button-row'>
                            <p style={{ lineHeight: "1" }}>Upload</p>
                            <img src={iconUpload} alt="upload icon" />
                        </div>
                    </button>
                </div>
                <div className='footer-sh-create' style={{ width: "100%" }}>
                    <TabBar />
                </div>
            </div>
            {isDivVisible && (
                <div className="sign-up-div">
                    <SignUp onToggle={toggleDivVisibility} />
                </div>
            )}

            {isDivVisibleGotItCreate && (
                <div className="sign-up-div">
                    <GotItCreate onToggleClose={toggleDivVisibilityGotItCreate} />
                </div>
            )}

            {isDivVisibleGotItCreate1 && (
                <div className="sign-up-div">
                    <GotItCreate1 onToggleClose1={toggleDivVisibilityGotItCreate1} />
                </div>
            )}

            {isDivVisibleComming && (
                <div className="sign-up-div">
                    <CommingSoon onToggle={toggleDivVisibilityComming} />
                </div>
            )}

            {isDivVisibleError && (
                <div className="sign-up-div">
                    <Error onToggle={toggleDivVisibilityError} />
                </div>
            )}

        </div>
    );
}

export default Create;