import React, { useState } from 'react';
import './css/toggle.css';
import toggle1 from './assets/images/toggle1.png';
import toggle2 from './assets/images/toggle2.png';

const Toggle = ({ onChange }) => {
  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
    if (onChange) {
      onChange(!isActive);
    }
  };

  return (
    <div 
      className={`toggle-container ${isActive ? 'active' : ''}`} 
      onClick={handleToggle}
    >
      <div className="toggle-button" />
      <img 
        src={toggle1} 
        alt="Left" 
        className="toggle-image toggle-image-left" 
      />
      <img 
        src={toggle2} 
        alt="Right" 
        className="toggle-image toggle-image-right" 
      />
    </div>
  );
};

export default Toggle;