import React, { useState, useEffect } from 'react';
import iconResultCreateItem from './assets/images/icon-create-item.png';
import iconGotItCheck from './assets/images/icon-check-green.png';
import './css/result-create.css';
import './css/result.css';

const ResultCreateItem = ({ text, isRightCorner, visible }) => {
    const [showCheck, setShowCheck] = useState(false);

    useEffect(() => {
        // Disable right-click
        const handleContextMenu = (e) => {
            e.preventDefault();
        };
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(text).then(() => {
            setShowCheck(true);
            setTimeout(() => {
                setShowCheck(false);
            }, 1000); // Show the check icon for 1 second
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    };

    return (
        <div className={`result-create-item ${isRightCorner ? 'corner-right' : 'corner-left'}` } style={{ userSelect: 'none' }}>
            <p className={`${visible ? '' : 'result-blur'}`}>{text}</p>
            {showCheck ? (
                <div className="check-icon-container">
                    <img src={iconGotItCheck} alt="check icon" />
                    {/* <p style={{fontSize:"8px"}}>copied!</p> */}
                </div>
            ) : (
                <img src={iconResultCreateItem} alt="icon back" onClick={visible ? copyToClipboard : null} style={{ cursor: visible ? 'pointer' : '' }} className={`${visible ? '' : 'result-blur'}`}/>
            )}
        </div>
    );
};

export default ResultCreateItem;