import { useState, useEffect } from 'react';

export const userLoginFunction = async (email, password, navigate) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/user-login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password }),
        });

        const data = await response.json();
        // console.log('JWT token Response from backend:', data);
        if (response.ok && data.ourToken) {
            // Save the token as needed
            // console.log('JWT token to sessionStorage:', data.ourToken);
            sessionStorage.setItem('session_token', data.ourToken);
            const returnLocation = sessionStorage.getItem('return_location');
            if (returnLocation === 'account') {
                navigate('/account');
            } else if (returnLocation === 'create') {
                if (window.location.pathname !== '/create') {
                    navigate('/create');
                } else {
                    window.location.reload();
                }
            } else {
                window.location.reload();
            }
        } else {
            // Handle different response codes
            let message = 'An unexpected error occurred';
            if (response.status === 500) {
                message = 'Internal server error';
            } else if (response.status === 401) {
                message = 'Invalid credentials';
            } else if (response.status === 404) {
                message = 'User not found';
            }

            // Navigate to the login page with a custom error message
            navigate('/login', { state: { message } });
        }
    } catch (error) {
        console.error('Error logging in:', error);
        navigate('/login', { state: { message: 'Invalid credentials' } });
    }
};

export const userSignupFunction = async (email, password, verification, navigate) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/user-signup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password, verification }),
        });

        const data = await response.json();
        // console.log('JWT token Response from backend:', data);
        if (response.ok && data.ourToken) {
            // Save the token as needed
            // console.log('JWT token to sessionStorage:', data.ourToken);
            sessionStorage.setItem('session_token', data.ourToken);
            const returnLocation = sessionStorage.getItem('return_location');
            if (returnLocation === 'account') {
                navigate('/account');
            } else if (returnLocation === 'create') {
                if (window.location.pathname !== '/create') {
                    navigate('/create');
                } else {
                    window.location.reload();
                }
            } else {
                window.location.reload();
            }
        } else {
            // Handle different response codes
            let message = 'An unexpected error occurred';
            if (response.status === 500) {
                message = 'Internal server error';
            } else if (response.status === 401) {
                message = 'Invalid credentials';
            }

            // Navigate to the login page with a custom error message
            navigate('/login', { state: { message } });
        }
    } catch (error) {
        console.error('Error logging in:', error);
        navigate('/login', { state: { message: 'Invalid credentials' } });
    }
};

export async function fetchUserData(setUserPicture, setUserEmail, setUserStatus, setUserId) {
    const token = sessionStorage.getItem('session_token');
    if (!token) {
        // console.log('No session token found.');
        window.location.href = '/login';
        return;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get-user`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.status === 404) {
            sessionStorage.removeItem('session_token');
            window.location.href = '/login';
        } else if (response.status === 200) {
            const userData = await response.json();
            // console.log('fetchUserData: ', userData); // Log the user data
            setUserPicture(userData.picture);
            setUserEmail(userData.email);
            setUserStatus(userData.status);
            setUserId(userData.id);
        } else {
            console.error('An unexpected error occurred:', response.status);
        }
    } catch (error) {
        console.error('Error fetching user data:', error);
    }
}

export async function fetchUserDataLogin(setUserPicture, setUserEmail, setUserStatus, setUserId) {
    const token = sessionStorage.getItem('session_token');
    if (!token) {
        // console.log('No session token found.');
        return;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get-user`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.status === 404) {
            sessionStorage.removeItem('session_token');
        } else if (response.status === 200) {
            const userData = await response.json();
            // console.log('fetchUserData: ', userData); // Log the user data
            setUserPicture(userData.picture);
            setUserEmail(userData.email);
            setUserStatus(userData.status);
            setUserId(userData.id);
        } else {
            console.error('An unexpected error occurred:', response.status);
        }
    } catch (error) {
        console.error('Error fetching user data:', error);
    }
}

export async function fetchUserDataVisibility(setUserPicture, setUserEmail, setUserStatus, setUserId) {
    const token = sessionStorage.getItem('session_token');
    if (!token) {
        // console.log('No session token found.');
        return;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get-user-visibility`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.status === 404) {
            sessionStorage.removeItem('session_token');
        } else if (response.status === 200) {
            const userData = await response.json();
            // console.log('fetchUserData: ', userData); // Log the user data
            setUserPicture(userData.picture);
            setUserEmail(userData.email);
            setUserStatus(userData.status);
            setUserId(userData.id);
        } else {
            console.error('An unexpected error occurred:', response.status);
        }
    } catch (error) {
        console.error('Error fetching user data:', error);
    }
}

export function useResponsiveWrapperVisibility() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        function checkVisibility() {
            const wrapper = document.querySelector('.responsive-wrapper');
            const newVisibility = !!wrapper && wrapper.style.display !== 'none';
            console.log('Checking visibility:', newVisibility);
            console.log('Wrapper:', wrapper);
            if (wrapper) {
                console.log('Wrapper display:', wrapper.style.display);
            }
            setIsVisible(newVisibility);
        }

        console.log('Setting up visibility check');

        // Check initially
        checkVisibility();

        // Set up a MutationObserver to watch for changes
        const observer = new MutationObserver(() => {
            console.log('DOM mutation detected');
            checkVisibility();
        });
        observer.observe(document.body, {
            childList: true,
            subtree: true,
            attributes: true,
            attributeFilter: ['style', 'class']
        });

        console.log('MutationObserver set up');

        // Clean up
        return () => {
            console.log('Cleaning up observer');
            observer.disconnect();
        };
    }, []);

    console.log('Current visibility state:', isVisible);

    return isVisible;
}