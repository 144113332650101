import React from 'react';
import './css/App.css';
import './css/logged-in.css';
import './css/comming-soon.css';
import iconClose from './assets/images/close.png';
import commingSoon from './assets/images/comming-soon.png';

function CommingSoon({ onToggle }) {

    return (
        <div className='comming-wrapper'>

            <div className='div-top-bottom' style={{ width: "100%" }}>
                <div style={{ width: "100%", display: "flex", justifyContent: 'end' }}>
                    {/* <Link to="/loading" style={{ "width": "auto" }}> */}
                    <div className='top-icons'><img onClick={onToggle} src={iconClose} alt="close icon" /></div>
                    {/* </Link> */}
                </div>
            </div>

            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly", weight: "100%", height: "100%" }}>
                <img src={commingSoon} style={{ marginTop: "15px" }} alt="wrenches" />

                <div className='comming-text'><p>Coming soon...</p></div>
                <div className='comming-text-small'>
                    <p>It seems like you’ve upload a face/profile of a male.<br />
                    We are working on introduction of <br />
                    this function to process male faces/profiles</p>
                </div>
            </div>

            <div className='first-footer' style={{ width: "100%", marginBottom: "0px" }}>
                <button
                    className= 'App-button'
                    onClick={onToggle}
                    
                    style={{ width: "100%" }}>
                    <div className='button-row'>
                     <p style={{ lineHeight: "1" }}>Try again</p>
                    </div>
                </button>
                
            </div>

        </div>

    );

}

export default CommingSoon;