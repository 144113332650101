import React, { useState, useEffect } from 'react';
import './css/history.css';
import './css/account.css';
import './css/success.css';
import './css/logged-in.css';
import iconArchive from './assets/images/empty-box.png';
import { fetchUserData } from './utils';
import { useResponsiveWrapperVisibility } from './utils';
import TabBar from './tabbar';
import HistoryItemView from './HistoryItemView';
import HistoryItemViewAnalyze from './HistoryItemViewAnalyze';

class MessageItem {
    constructor(message, visible, date, history, type) {
        this.message = message;
        this.visible = visible;
        this.date = date;
        this.history = history;
        this.type = type;
    }
}

class HistoryItem {
    constructor(image, messages) {
        this.image = image;
        this.messages = messages;
        this.date = Math.min(...messages.map(msg => msg.date));
    }
}

const isAndroid = () => {
    return /Android/i.test(navigator.userAgent);
};

function History() {
    const desktopWidth = 768;
    const [isMobile, setIsMobile] = useState(window.innerWidth < desktopWidth);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < desktopWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const [userId, setUserId] = useState(null);
    const [userStatus, setUserStatus] = useState(null);
    const [userHistory, setUserHistory] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);

    // useEffect(() => {
    //     // Mock user data
    //     setUserStatus('active');
    //     setUserId('mockUserId');
    // }, []);

    // useEffect(() => {
    //     // Mock history data
    //     const mockHistoryData = [
    //         {
    //             image: 'https://imageio.forbes.com/specials-images/imageserve/5f9a0679ca21fdb232d2768d/Ten-Career-Tips-for-Every-Working-Woman/960x0.jpg',
    //             messages: [
    //                 { message: 'Test message 3', date: new Date('2023-02-01').getTime(), history: [], type: 'analyze' },
    //             ]
    //         },
    //         {
    //             image: 'https://imageio.forbes.com/specials-images/imageserve/5f9a0679ca21fdb232d2768d/Ten-Career-Tips-for-Every-Working-Woman/960x0.jpg',
    //             messages: [
    //                 { message: 'Test message 3', date: new Date('2023-02-01').getTime(), history: [], type: 'analyze' },
    //             ]
    //         },
    //         {
    //             image: 'https://imageio.forbes.com/specials-images/imageserve/5f9a0679ca21fdb232d2768d/Ten-Career-Tips-for-Every-Working-Woman/960x0.jpg',
    //             messages: [
    //                 { message: 'Test message 3', date: new Date('2023-02-01').getTime(), history: [], type: 'analyze' },
    //             ]
    //         },
    //         {
    //             image: 'https://imageio.forbes.com/specials-images/imageserve/5f9a0679ca21fdb232d2768d/Ten-Career-Tips-for-Every-Working-Woman/960x0.jpg',
    //             messages: [
    //                 { message: 'Test message 3', date: new Date('2023-02-01').getTime(), history: [], type: 'analyze' },
    //             ]
    //         },
    //         {
    //             image: 'https://imageio.forbes.com/specials-images/imageserve/5f9a0679ca21fdb232d2768d/Ten-Career-Tips-for-Every-Working-Woman/960x0.jpg',
    //             messages: [
    //                 { message: 'Test message 3', date: new Date('2023-02-01').getTime(), history: [], type: 'analyze' },
    //             ]
    //         },
    //         {
    //             image: 'https://imageio.forbes.com/specials-images/imageserve/5f9a0679ca21fdb232d2768d/Ten-Career-Tips-for-Every-Working-Woman/960x0.jpg',
    //             messages: [
    //                 { message: 'Test message 3', date: new Date('2023-02-01').getTime(), history: [], type: 'analyze' },
    //             ]
    //         },
    //         {
    //             image: 'https://imageio.forbes.com/specials-images/imageserve/5f9a0679ca21fdb232d2768d/Ten-Career-Tips-for-Every-Working-Woman/960x0.jpg',
    //             messages: [
    //                 { message: 'Test message 3', date: new Date('2023-02-01').getTime(), history: [], type: 'analyze' },
    //             ]
    //         },
    //         {
    //             image: 'https://imageio.forbes.com/specials-images/imageserve/5f9a0679ca21fdb232d2768d/Ten-Career-Tips-for-Every-Working-Woman/960x0.jpg',
    //             messages: [
    //                 { message: 'Test message 3', date: new Date('2023-02-01').getTime(), history: [], type: 'analyze' },
    //             ]
    //         },
    //         {
    //             image: 'https://imageio.forbes.com/specials-images/imageserve/5f9a0679ca21fdb232d2768d/Ten-Career-Tips-for-Every-Working-Woman/960x0.jpg',
    //             messages: [
    //                 { message: 'Test message 3', date: new Date('2023-02-01').getTime(), history: [], type: 'analyze' },
    //             ]
    //         },
    //         {
    //             image: 'https://imageio.forbes.com/specials-images/imageserve/5f9a0679ca21fdb232d2768d/Ten-Career-Tips-for-Every-Working-Woman/960x0.jpg',
    //             messages: [
    //                 { message: 'Test message 3', date: new Date('2023-02-01').getTime(), history: [], type: 'analyze' },
    //             ]
    //         },
    //         {
    //             image: 'https://imageio.forbes.com/specials-images/imageserve/5f9a0679ca21fdb232d2768d/Ten-Career-Tips-for-Every-Working-Woman/960x0.jpg',
    //             messages: [
    //                 { message: 'Test message 3', date: new Date('2023-02-01').getTime(), history: [], type: 'analyze' },
    //             ]
    //         },
    //         {
    //             image: 'https://imageio.forbes.com/specials-images/imageserve/5f9a0679ca21fdb232d2768d/Ten-Career-Tips-for-Every-Working-Woman/960x0.jpg',
    //             messages: [
    //                 { message: 'Test message 3', date: new Date('2023-02-01').getTime(), history: [], type: 'analyze' },
    //             ]
    //         },
    //         {
    //             image: 'https://imageio.forbes.com/specials-images/imageserve/5f9a0679ca21fdb232d2768d/Ten-Career-Tips-for-Every-Working-Woman/960x0.jpg',
    //             messages: [
    //                 { message: 'Test message 3', date: new Date('2023-02-01').getTime(), history: [], type: 'profile' },
    //             ]
    //         },
    //         {
    //             image: 'https://imageio.forbes.com/specials-images/imageserve/5f9a0679ca21fdb232d2768d/Ten-Career-Tips-for-Every-Working-Woman/960x0.jpg',
    //             messages: [
    //                 { message: 'Test message 3', date: new Date('2023-02-01').getTime(), history: [], type: 'profile' },
    //             ]
    //         },
    //         // Add more mock items as needed
    //     ];

    //     const historyItems = mockHistoryData.map(item => {
    //         const messageItems = item.messages.map(msg => new MessageItem(
    //             msg.message,
    //             userStatus === 'active' ? 1 : 0,
    //             msg.date,
    //             msg.history,
    //             msg.type
    //         ));
    //         return new HistoryItem(item.image, messageItems);
    //     });

    //     setUserHistory(historyItems);
    // }, [userStatus]);

    useEffect(() => {
        fetchUserData(() => { }, () => { }, setUserStatus, setUserId);
    }, []);

    useEffect(() => {
        const fetchUserHistory = async () => {
            if (userId) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/history/${userId}`);
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    const data = await response.json();
                    console.log("data: ", data);
                    const historyItems = Object.entries(data).map(([image, messages]) => {
                        const messageItems = messages.map(msg => new MessageItem(
                            msg.message,
                            userStatus === 'active' ? 1 : 0,
                            msg.date,
                            msg.history,
                            msg.type
                        ));
                        return new HistoryItem(image, messageItems);
                    });
                    setUserHistory(historyItems);
                } catch (error) {
                    console.error("Failed to fetch user history:", error);
                }
            }
        };

        fetchUserHistory();
    }, [userId]);

    const openGallery = (item) => {
        setSelectedItem(item);
    };

    const closeGallery = () => {
        setSelectedItem(null);
    };

    const isWrapperVisible = useResponsiveWrapperVisibility();

    const [isAndroidDevice, setIsAndroidDevice] = useState(false);

    useEffect(() => {
        setIsAndroidDevice(isAndroid());
    }, []);

    return (
        <div className={`App ${isWrapperVisible ? 'App-banner-visible' : ''} ${isAndroidDevice ? 'android-device' : ''}`}>
            {/* <div className='div-top-bottom'>
                <div style={{ width: "100%", display: "flex", justifyContent: 'space-between', alignItems: "start", marginTop: "20px" }}>
                    <Link to="/logged-in">
                        <div className='top-icons'><img src={iconBack} alt="back icon" /></div>
                    </Link>
                    <Link to="/logged-in">
                        <div className='top-icons'><img src={iconSettingWhite} alt="settings icon" /></div>
                    </Link>
                </div>
            </div> */}

            <h1>History</h1>

            {userHistory === null ? (
                <div style={{ height: "50vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <p>Loading...</p>
                </div>
            ) : userHistory.length === 0 ? (
                <div style={{ height: "50vh", width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                    <div className='history-circle-archive' >
                        <img src={iconArchive} className='no-border-image orange-filter' alt="Archive Icon" />
                    </div>
                    <div>
                        <p className='history-archive-text'>You have no saved generations</p>
                    </div>
                </div>
            ) : (
                <div className={`scrollable-div ${isWrapperVisible ? 'scrollable-div-height-banner' : 'scrollable-div-height'}`}>
                    {userHistory.reduce((rows, item, index) => {
                        const itemsPerRow = isMobile ? 2 : 4;
                        if (index % itemsPerRow === 0) {
                            rows.push([]);
                        }
                        rows[rows.length - 1].push(
                            <div className="history-item-container" key={item.image} onClick={() => openGallery(item)}>
                                <img src={item.image} alt="History" className="history-image" />
                                <div className="analyze-button">{item.messages[0].type === 'profile' ? "Create" : "Analyze"}</div>
                            </div>
                        );
                        return rows;
                    }, []).map((row, index) => (
                        <div className="history-row" key={index}>
                            {row}
                        </div>
                    ))}
                </div>
            )}

            <TabBar />

            {selectedItem && (
                selectedItem.messages[0].type === 'profile' ?
                    <HistoryItemView item={selectedItem} onClose={closeGallery} userStatus={userStatus} /> :
                    <HistoryItemViewAnalyze item={selectedItem} onClose={closeGallery} userStatus={userStatus} />
            )}
        </div>
    );
}

export default History;